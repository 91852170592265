import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import defaulttheme from "../../customTheme.js";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { Button } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { getStatistics } from "../../utils/StatisticsApi.js";
import DonutChart from "./DonutChart.js";
import MyBarChart from "./MyBarChart.js";
import { getMappingByParkingSpaceIds } from "../../utils/ReportApi";
import { getRealTimeDeviceReport } from "../../utils/ReportApi";

import Tooltip from "@material-ui/core/Tooltip";
import BatteryStdIcon from "@material-ui/icons/BatteryStd";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(5),
    height: "95%",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%", // 使用100%高度
  },

  gridContainer: {
    height: "50%",
  },
  centered: {
    // 加上逗號
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  countUp: {
    // 加上逗號
    fontSize: "256px" /* 調整您想要的放大尺寸 */,
  },

  deviceText: {
    color: "white", // 設置文字顏色為白色
    fontSize: 24, // 設置字體大小
    textAlign: "left", // 設置靠左對齊
    display: "block",
    marginTop: "15px",
  },

  deviceValue: {
    color: "white",
    fontSize: 96, // 設置字體大小
    textAlign: "center", // 設置靠左對齊
    display: "block",
    fontWeight: "bold",
    marginTop: "20px",
  },
  deviceButton: {
    position: "absolute", // 絕對定位
    bottom: "10px", // 距離底部 10px
    right: "10px", // 距離右邊 10px
    color: "white",
  },
  TicketText: {
    color: "white", // 設置文字顏色為白色
    fontSize: 24, // 設置字體大小
    textAlign: "left", // 設置靠左對齊
    display: "block",
    marginTop: "5px",
    marginLeft: "10px",
  },

  TicketValue: {
    color: "white",
    fontSize: 36, // 設置字體大小
    textAlign: "right", // 設置靠左對齊
    display: "block",
    fontWeight: "bold",
    marginBottom: "15px",
    marginRight: "15px",
  },
}));

const dataSet_default = [
  {
    label: "停車單數", // 第二個 label
    data: [25, 12, 15, 8, 14, 22, 8, 18, 24, 13, 22, 19],
    backgroundColor: "#6490aa", // 顏色
    borderColor: "#6490aa",
    borderWidth: 1,
  },
];

const barLabel = [
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
];

const dataSet_default2 = [
  {
    label: "開單前緩衝離場", // 第一個 label
    data: [10, 19, 8, 11, 16, 15, 10, 8, 6, 7, 12, 13],
    backgroundColor: "#717acb", // 顏色
    borderColor: "#717acb",
    borderWidth: 1,
  },
  {
    label: "開單後緩衝離場", // 第二個 label
    data: [25, 12, 15, 8, 14, 22, 8, 18, 24, 10, 14, 3],
    backgroundColor: "#50ceff", // 顏色
    borderColor: "#50ceff",
    borderWidth: 1,
  },
  {
    label: "開單後成功收費", // 第二個 label
    data: [10, 13, 15, 25, 12, 15, 8, 14, 22, 8, 18, 24],
    backgroundColor: "#79C850", // 顏色
    borderColor: "#79C850",
    borderWidth: 1,
  },
];

const lprFailureChartDataColor = ["#ff0000", "grey"];
const duplicatedChartDataColor = ["#ff9abe", "grey"];
const duplcatedWithChargeChartDataColor = ["#f28e2b", "grey"];
const occupiedRateChartColor = ["#0ca9d6", "grey"];
const startOrderRateChartColor = ["#b0115d", "grey"];
const startOrderWithChargeChartColor = ["#fcc10a", "grey"];
const turnoverRateChartColor = ["#8fee43", "grey"];

const Dashboard = () => {
  const classes = useStyles();

  const { t, showSnackbar } = useContext(GlobalContext);
  const [onlineDeviceNum, setOnlineDeviceNum] = React.useState(0);
  const [noticeDeviceNum, setNoticeDeviceNum] = React.useState(0);
  const [offlineDeviceNum, setOfflineDeviceNum] = React.useState(0);
  const [moduleFailureDeviceNum, setModuleFailureDeviceNum] = React.useState(0);
  const [openDeviceDialog, setOpenDeviceDialog] = React.useState(false);
  const [openModuleFailureDialog, setOpenModuleFailure] = React.useState(false);
  const [displayDevices, setDisplayDevices] = React.useState([]);
  const [onlineDevice, setOnlineDevice] = React.useState([]);
  const [offlineDevice, setOfflineDevice] = React.useState([]);
  const [noticeDevice, setNoticeDevice] = React.useState([]);
  const [moduleFailureDevice, setModuleFailureDevice] = React.useState([]);
  const [dlgTitle, setDlgTitle] = React.useState("");
  const [turnoverWithCheckPoint, setTurnoverWithCheckPoint] = React.useState(0);
  const [amountOfDuplicatedTicket, setAmountOfDuplicatedTicket] =
    React.useState(0);
  const [
    amountOfDuplicatedTicketWithCheckPoint,
    setAmountOfDuplicatedTicketWithCheckPoint,
  ] = React.useState(0);
  const [statisticsKey, setStatisticsKey] = React.useState("");
  const [lprFailureChartData, setLprFailureChartData] = React.useState(null);
  const [duplicatedTicketChartData, setDuplicatedTicketChartData] =
    React.useState(null);
  const [
    duplicatedTicketWithCheckPointChartData,
    setDuplicatedTicketWithCheckPointChartData,
  ] = React.useState(null);
  const [parkingOccupancyRateChartData, setParkingOccupancyRateChartData] =
    React.useState(null);
  const [
    parkingTicketCreationRateChartData,
    setParkingTicketCreationRateChartData,
  ] = React.useState(null);
  const [parkingTurnoverRateChartData, setParkingTurnoverRateChartData] =
    React.useState(null);
  const [
    parkingTicketWithCheckPointRateChartData,
    setPrkingTicketWithCheckPointRateChartData,
  ] = React.useState(null);
  const [dataSetOccupancy, SetDataSetOccupancy] =
    React.useState(dataSet_default);
  const [dataSetMultiBar, SetDataSetMultiBar] =
    React.useState(dataSet_default2);

  const [openInfoDlg, setOpenInfoDlg] = React.useState(false);
  const [infoDlgTitle, setInfoDlgTitle] = React.useState("");
  const [infoDlgContent, setInfoDlgContent] = React.useState("");
  const [infoData, setInfoData] = React.useState(null);

  useEffect(() => {
    // 在組件加載的時候得到資料

    GetRealTimeDeviceStatus();
    GetStatisticsData();
  }, []);

  useEffect(() => {
    // 讀取說明檔案
    fetch("/DashboardInfo.json") // 從 public 資料夾讀取
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setInfoData(data); // 更新狀態
        console.log("Fetched data:", data); // 確保只在資料取得後 log
      })
      .catch((error) => console.error("Error fetching JSON:", error));
  }, []); // 空的依賴陣列，確保只執行一次

  const GetRealTimeDeviceStatus = async () => {
    try {
      var response = await getRealTimeDeviceReport();
      var resp = response.data;
      var deviceStatus = resp.data;

      setOnlineDeviceNum(deviceStatus.online);
      setModuleFailureDeviceNum(deviceStatus.moduleFailure);
      setOfflineDeviceNum(deviceStatus.offline);
      setNoticeDeviceNum(deviceStatus.notice);

      setDevices(deviceStatus.onlineDevice, setOnlineDevice);
      setDevices(deviceStatus.noticeDevice, setNoticeDevice);
      setDevices(deviceStatus.offlineDevice, setOfflineDevice);
      setDevices(deviceStatus.moduleFailureDevice, setModuleFailureDevice);
    } catch (error) {
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const GetStatisticsData = async () => {
    try {
      // 計算昨天的日期
      const now = new Date();
      const yesterday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1
      );

      // 格式化成 ISO 字串
      const startDT = yesterday.toISOString();

      const response = await getStatistics(startDT);
      const resp = response.data;

      const dateKeys = Object.keys(resp.data); // 取得 data 下的所有鍵值
      const dynamicDateKey = dateKeys[0]; // 假設只需要第一個日期鍵值

      setStatisticsKey(dynamicDateKey);
      // 獲取該日期的內容
      const statData = resp.data[dynamicDateKey];

      let withCheckPoint = 0;
      let duplicatedTicket = 0;
      let duplicatedTicketWithCheckPoint = 0;

      let duplicateTicketRateWithCheckPoint = 0;
      let parkingOccupancyRate = 0;
      let parkingTicketCreationRate = 0;
      let parkingTicketWithCheckPointRate = 0;
      let parkingTurnoverRate = 0;
      let plateRecognitionFailureRate = 0;
      let duplicatedTicketRate = 0;

      const occupancyTimes = {};
      const turnoverWithinBufferTimes = {};
      const turnoverWithinFirstReportTimes = {};
      const turnoverWithCheckPointTimes = {};
      barLabel.forEach((label) => {
        occupancyTimes[label] = 0;
        turnoverWithinBufferTimes[label] = 0;
        turnoverWithinFirstReportTimes[label] = 0;
        turnoverWithCheckPointTimes[label] = 0;
      });

      statData.forEach((data) => {
        withCheckPoint += data.turnoverWithCheckPoint;
        duplicatedTicket += data.amountOfDuplicatedTicket;
        duplicatedTicketWithCheckPoint +=
          data.amountOfDuplicatedTicketWithCheckPoint;
        plateRecognitionFailureRate += data.plateRecognitionFailureRate;
        parkingTurnoverRate += data.parkingTurnoverRate;
        parkingTicketWithCheckPointRate += data.parkingTicketWithCheckPointRate;
        parkingTicketCreationRate += data.parkingTicketCreationRate;
        parkingOccupancyRate += data.parkingOccupancyRate;
        duplicateTicketRateWithCheckPoint +=
          data.duplicateTicketRateWithCheckPoint;
        duplicatedTicketRate += data.duplicatedTicketRate;

        calculateData(
          data,
          occupancyTimes,
          turnoverWithinBufferTimes,
          turnoverWithinFirstReportTimes,
          turnoverWithCheckPointTimes
        );
      });

      const updatedData = [
        {
          ...dataSet_default[0], // 保留其他屬性
          data: Object.values(occupancyTimes), // 更新數據
        },
      ];
      SetDataSetOccupancy(updatedData); // 使用 setState 更新數據

      const updatedData_MulitBar = [
        {
          ...dataSet_default2[0],
          data: Object.values(turnoverWithinBufferTimes),
        },
        {
          ...dataSet_default2[1],
          data: Object.values(turnoverWithinFirstReportTimes),
        },
        {
          ...dataSet_default2[2],
          data: Object.values(turnoverWithCheckPointTimes),
        },
      ];
      SetDataSetMultiBar(updatedData_MulitBar);

      //車牌辨識失敗率
      const lprFailureRate = Math.round(
        plateRecognitionFailureRate / statData.length
      );
      setLprFailureChartData([lprFailureRate, 100 - lprFailureRate]);

      //重複開單率
      const duplicatedTicketRateNumber = Math.round(
        duplicatedTicketRate / statData.length
      );
      setDuplicatedTicketChartData([
        duplicatedTicketRateNumber,
        100 - duplicatedTicketRateNumber,
      ]);

      //重複開單收費率
      const duplicateTicketRateWithCheckPointNumber = Math.round(
        duplicateTicketRateWithCheckPoint / statData.length
      );
      setDuplicatedTicketWithCheckPointChartData([
        duplicateTicketRateWithCheckPointNumber,
        100 - duplicateTicketRateWithCheckPointNumber,
      ]);

      //停車率
      const parkingOccupancyRateNumber = Math.round(
        parkingOccupancyRate / statData.length
      );
      setParkingOccupancyRateChartData([
        parkingOccupancyRateNumber,
        100 - parkingOccupancyRateNumber,
      ]);

      //開單率
      const parkingTicketCreationRateNumber = Math.round(
        parkingTicketCreationRate / statData.length
      );
      setParkingTicketCreationRateChartData([
        parkingTicketCreationRateNumber,
        100 - parkingTicketCreationRateNumber,
      ]);

      //開單收費率

      const parkingTicketWithCheckPointRateNumber = Math.round(
        parkingTicketWithCheckPointRate / statData.length
      );
      setPrkingTicketWithCheckPointRateChartData([
        parkingTicketWithCheckPointRateNumber,
        100 - parkingTicketWithCheckPointRateNumber,
      ]);

      //迴轉率
      const parkingTurnoverRateNumber = Math.round(
        parkingTurnoverRate / statData.length
      );
      setParkingTurnoverRateChartData([
        parkingTurnoverRateNumber,
        100 - parkingTurnoverRateNumber,
      ]);

      setTurnoverWithCheckPoint(withCheckPoint);
      setAmountOfDuplicatedTicket(duplicatedTicket);
      setAmountOfDuplicatedTicketWithCheckPoint(duplicatedTicketWithCheckPoint);
    } catch (error) {
      console.log(error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const calculateData = (
    data,
    occupancyTimes,
    turnoverWithinBufferTimes,
    turnoverWithinFirstReportTimes,
    turnoverWithCheckPointTimes
  ) => {
    // 停放次數
    MatchTime(data.totalTurnoverTicketIds, occupancyTimes);
    MatchTime(
      data.turnoverWithinBufferTimeTicketIds,
      turnoverWithinBufferTimes
    );
    MatchTime(
      data.turnoverWithinFirstCheckPointTicketIds,
      turnoverWithinFirstReportTimes
    );
    MatchTime(
      data.turnoverWithCheckPointTicketIds,
      turnoverWithCheckPointTimes
    );
  };

  const MatchTime = (Ids, timeObject) => {
    let totalTurnoverTicketTime = Ids.map((id) => id.createAt).filter(
      (time) => time !== null
    ); // 過濾掉可能為 null 的值

    totalTurnoverTicketTime.forEach((isoTime) => {
      // 轉換為 Local 時間
      const localDate = new Date(isoTime);
      const hours = localDate.getHours(); // 取得小時 (Local 時間)

      // 將小時對應到時段 Key
      const matchedKey = `${hours}:00`;
      if (timeObject[matchedKey] !== undefined) {
        timeObject[matchedKey] += 1; // 累加次數
      }
    });
  };

  const setDevices = async (devices, setStateFunction) => {
    if (devices.length <= 0) return;

    // 提取所有的 parkingSpaceId
    const parkingSpaceIds = devices.map((device) => device.parkingSpaceId);

    // 根據 parkingSpaceIds 獲取映射
    const response = await getMappingByParkingSpaceIds(parkingSpaceIds);
    const mappings = response.data.data;

    // 處理映射數據，並找到對應的 DeviceStatus
    const processedDevices = await Promise.all(
      mappings.map((mapping) => {
        const DeviceStatus = devices.find(
          (status) => status.deviceId === mapping.deviceId
        );
        return {
          deviceId: mapping.deviceId,
          parkingSpaceName: mapping.parkingSpaceName,
          parkingLotName: mapping.parkingLotName,
          parkingLocation: mapping.locationName,
          deviceStatus: DeviceStatus,
        };
      })
    );

    // 更新狀態
    setStateFunction(processedDevices);
  };

  const handleOpenDialog = (event) => {
    const buttonName = event.currentTarget.getAttribute("data-button");
    if (buttonName === "Online") {
      setDlgTitle("上線設備");
      setDisplayDevices(onlineDevice);
      setOpenDeviceDialog(true);
    } else if (buttonName === "offline") {
      setDlgTitle("離線設備");
      setDisplayDevices(offlineDevice);
      setOpenDeviceDialog(true);
    } else if (buttonName === "notice") {
      setDlgTitle("告警設備");
      setDisplayDevices(noticeDevice);
      setOpenDeviceDialog(true);
    } else if (buttonName === "moduleFailure") {
      setDlgTitle("模組異常設備");
      setDisplayDevices(moduleFailureDevice);
      setOpenModuleFailure(true);
    }
  };

  const handleCloseDeviceDlg = () => {
    setOpenDeviceDialog(false);
  };

  const handleCloseModuleFailureDlg = () => {
    setOpenModuleFailure(false);
  };

  const GetBatteryIcon = (battery) => {
    // 設置 Tooltip 的 title 動態內容
    const tooltipTitle = `id: ${battery.id}, 電量: ${
      battery.percentage
    }%, 電壓: ${battery.voltage} ,狀態: ${
      battery.status === 0 ? "正常" : "異常"
    }`;

    // 根據狀態選擇圖標和顏色
    const iconStyle = { color: battery.status === 0 ? "green" : "red" };

    return (
      <Tooltip title={tooltipTitle} arrow>
        {battery.status === 0 ? (
          <BatteryStdIcon style={iconStyle} />
        ) : (
          <BatteryAlertIcon style={iconStyle} />
        )}
      </Tooltip>
    );
  };

  const GetSolarPanelIcon = (solarPanel) => {
    const tooltipTitle = `供電狀態: ${
      solarPanel.status === 1
        ? "充電中"
        : solarPanel.power == 1
        ? "供電中"
        : "N/A"
    },  
  設備狀態 : ${
    solarPanel.status === 1 && solarPanel.power === 1 ? "異常" : "正常"
  }`;

    // 根據狀態選擇圖標和顏色
    const iconStyle = {
      color:
        solarPanel.status === 1 && solarPanel.power === 1 ? "red" : "green",
    };
    return (
      <Tooltip title={tooltipTitle} arrow>
        <WbSunnyIcon style={iconStyle} />
      </Tooltip>
    );
  };

  const GetRadarIcon = (radar) => {
    const tooltipTitle = `id: ${radar.id}, 狀態: ${
      radar.status === 0 ? "正常" : "異常"
    }`;

    // 根據狀態選擇圖標和顏色
    const iconStyle = { color: radar.status === 0 ? "green" : "red" };
    return (
      <Tooltip title={tooltipTitle} arrow>
        <TrackChangesIcon style={iconStyle} />
      </Tooltip>
    );
  };

  const GetCameraIcon = (camera) => {
    // 設置 Tooltip 的 title 動態內容
    const tooltipTitle = `id: ${camera.id}, 狀態: ${
      camera.status === 0 ? "正常" : "異常"
    }`;

    // 根據狀態選擇圖標和顏色
    const iconStyle = { color: camera.status === 0 ? "green" : "red" };

    return (
      <Tooltip title={tooltipTitle} arrow>
        {camera.status === 0 ? (
          <VideocamIcon style={iconStyle} />
        ) : (
          <VideocamOffIcon style={iconStyle} />
        )}
      </Tooltip>
    );
  };

  const GetStatuslIcon = (status) => {
    const tooltipTitle = `狀態: ${status === 0 ? "正常" : "異常"}`;
    const iconStyle = { color: status === 0 ? "green" : "red" };

    return (
      <Tooltip title={tooltipTitle} arrow>
        {status === 0 ? (
          <CheckCircleIcon style={iconStyle} />
        ) : (
          <CancelIcon style={iconStyle} />
        )}
      </Tooltip>
    );
  };

  const handleCloseInfoDlg = () => {
    setOpenInfoDlg(false);
  };

  const handleOpenInfoDlg = (target) => {
    const content = infoData[target]?.fullinfo || "無說明";
    const title = infoData[target]?.title || "無說明";

    console.log(title);
    setInfoDlgContent(content);
    setInfoDlgTitle(title);
    setOpenInfoDlg(true);
  };

  const InfomationIconButton = (target) => {
    if (!infoData) return;
    const tooltip = infoData[target]?.tooltip || "無說明";

    return (
      <Tooltip
        title={
          <span
            style={{
              fontSize: "16px",
              color: "white",
              border: "10px",
            }}
          >
            {tooltip}
          </span>
        }
        sx={{
          "& .MuiTooltip-tooltip": {
            fontSize: "16px",
          },
        }}
      >
        <InfoIcon
          style={{ color: "#0547c0" }}
          onClick={() => handleOpenInfoDlg(target)}
        ></InfoIcon>
      </Tooltip>
    );
  };

  const RenderInfomationDilaog = (
    <Dialog
      maxWidth="xl"
      onClose={handleCloseInfoDlg}
      aria-labelledby="simple-dialog-title"
      open={openInfoDlg}
    >
      <DialogTitle id="simple-dialog-title">
        <h1>{infoDlgTitle}</h1>
      </DialogTitle>
      <DialogContent>
        <p style={{ whiteSpace: "pre-line", fontSize: "24px" }}>
          {infoDlgContent}
        </p>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseInfoDlg}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderModuleFailureDeviceDialog = (
    <Dialog
      maxWidth="xl"
      onClose={handleCloseModuleFailureDlg}
      aria-labelledby="simple-dialog-title"
      open={openModuleFailureDialog}
    >
      <DialogTitle id="simple-dialog-title">{dlgTitle}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: defaulttheme.blue.delta,
                  color: "white",
                }}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  height: "10px",
                }}
              >
                {ModuleFailureListHeader.map((header) => (
                  <TableCell
                    key={header}
                    align="left"
                    style={{ color: "white", fontSize: 16 }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayDevices
                ? displayDevices.map((device, index) => (
                    <TableRow
                      key={device.deviceStatus.parkingSpaceId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      style={{
                        backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd",
                      }}
                    >
                      <TableCell>{device.parkingLocation}</TableCell>
                      <TableCell align="left">
                        {device.parkingLotName}
                      </TableCell>
                      <TableCell align="left">
                        {device.parkingSpaceName}
                      </TableCell>
                      <TableCell align="left">{device.deviceId}</TableCell>
                      <TableCell align="left">
                        {ISO8601ToDateTime(device.deviceStatus.lastHeartbeat)}
                      </TableCell>
                      <TableCell align="center">
                        {device.deviceStatus.batteries
                          ? device.deviceStatus.batteries.map(
                              (battery, index) => (
                                <span
                                  key={`battery${index}`}
                                  style={{ marginRight: "20px" }}
                                >
                                  {GetBatteryIcon(battery)}
                                </span>
                              )
                            )
                          : null}
                      </TableCell>

                      <TableCell align="center">
                        {device.deviceStatus.solarPanel ? (
                          <span
                            key={`solarPanel${index}`}
                            style={{ marginRight: "20px" }}
                          >
                            {GetSolarPanelIcon(device.deviceStatus.solarPanel)}
                          </span>
                        ) : null}
                      </TableCell>

                      <TableCell align="center">
                        {device.deviceStatus.radars
                          ? device.deviceStatus.radars.map((radar, index) => (
                              <span
                                key={`radar${index}`}
                                style={{ marginRight: "20px" }}
                              >
                                {GetRadarIcon(radar)}
                              </span>
                            ))
                          : null}
                      </TableCell>
                      <TableCell align="center">
                        {device.deviceStatus.cameras
                          ? device.deviceStatus.cameras.map((camera, index) => (
                              <span
                                key={`cam${index}`}
                                style={{ marginRight: "20px" }}
                              >
                                {GetCameraIcon(camera)}
                              </span>
                            ))
                          : null}
                      </TableCell>
                      <TableCell align="center">
                        {device.deviceStatus.touchPanel ? (
                          <span
                            key={`touchPanel${index}`}
                            style={{ marginRight: "20px" }}
                          >
                            {GetStatuslIcon(
                              device.deviceStatus.touchPanel.status
                            )}
                          </span>
                        ) : null}
                      </TableCell>
                      <TableCell align="center">
                        {device.deviceStatus.gSensor ? (
                          <span
                            key={`gSensor${index}`}
                            style={{ marginRight: "20px" }}
                          >
                            {GetStatuslIcon(device.deviceStatus.gSensor.status)}
                          </span>
                        ) : null}
                      </TableCell>
                      <TableCell align="center">
                        {device.deviceStatus.cardReader ? (
                          <span
                            key={`cardReader${index}`}
                            style={{ marginRight: "8px" }}
                          >
                            {GetStatuslIcon(
                              device.deviceStatus.cardReader.status
                            )}
                          </span>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        app version: {device.deviceStatus.system.app_version}
                        <br />
                        m0 version: {device.deviceStatus.system.m0_version}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseModuleFailureDlg}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderDeviceDialog = (
    <Dialog
      maxWidth="xl"
      onClose={handleCloseDeviceDlg}
      aria-labelledby="simple-dialog-title"
      open={openDeviceDialog}
    >
      <DialogTitle id="simple-dialog-title">{dlgTitle}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: defaulttheme.blue.delta,
                  color: "white",
                }}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  height: "10px",
                }}
              >
                {DeviceListHeader.map((header) => (
                  <TableCell
                    key={header}
                    align="left"
                    style={{ color: "white", fontSize: 16 }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayDevices
                ? displayDevices.map((device, index) => (
                    <TableRow
                      key={device.deviceStatus.parkingSpaceId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      style={{
                        backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd",
                      }}
                    >
                      <TableCell>{device.parkingLocation}</TableCell>
                      <TableCell align="left">
                        {device.parkingLotName}
                      </TableCell>
                      <TableCell align="left">
                        {device.parkingSpaceName}
                      </TableCell>
                      <TableCell align="left">{device.deviceId}</TableCell>
                      <TableCell align="left">
                        {ISO8601ToDateTime(device.deviceStatus.lastHeartbeat)}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseDeviceDlg}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "1fr 1fr 1fr",
        height: "90%",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Paper
            className={classes.paper}
            style={{
              backgroundColor: "#3b84d7",
              position: "relative", // 使 Paper 成為相對定位容器
            }}
          >
            <label className={classes.deviceText}>上線設備</label>
            <label className={classes.deviceValue}>{onlineDeviceNum}</label>
            <IconButton
              data-button="Online"
              className={classes.deviceButton}
              onClick={handleOpenDialog}
            >
              <ListIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={classes.paper}
            style={{
              backgroundColor: "#e49e58",
              position: "relative", // 使 Paper 成為相對定位容器
            }}
          >
            <label className={classes.deviceText}>告警設備</label>
            <label className={classes.deviceValue}>{noticeDeviceNum}</label>

            <IconButton
              data-button="notice"
              className={classes.deviceButton}
              onClick={handleOpenDialog}
            >
              <ListIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={classes.paper}
            style={{
              backgroundColor: "#d13232",
              position: "relative", // 使 Paper 成為相對定位容器
            }}
          >
            <label className={classes.deviceText}>離線設備</label>
            <label className={classes.deviceValue}>{offlineDeviceNum}</label>

            <IconButton
              data-button="offline"
              className={classes.deviceButton}
              onClick={handleOpenDialog}
            >
              <ListIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={classes.paper}
            style={{
              backgroundColor: "#79c075",
              position: "relative", // 使 Paper 成為相對定位容器
            }}
          >
            <label className={classes.deviceText}>設備模組異常</label>
            <label className={classes.deviceValue}>
              {moduleFailureDeviceNum}
            </label>

            <IconButton
              data-button="moduleFailure"
              className={classes.deviceButton}
              onClick={handleOpenDialog}
            >
              <ListIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Paper
            className={classes.paper}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "95%",
              alignItems: "flex-start", // 子項目在水平方向靠左
            }}
          >
            <Paper
              style={{
                width: "100%",
                height: "30%",
                backgroundColor: "#5073ab",
              }}
            >
              <label className={classes.TicketText}>加簽次數</label>
              <label className={classes.TicketValue}>
                {turnoverWithCheckPoint}
              </label>
            </Paper>
            <Paper
              style={{
                width: "100%",
                height: "30%",
                backgroundColor: "#5d2b65",
              }}
            >
              <label className={classes.TicketText}>重複開單</label>
              <label className={classes.TicketValue}>
                {amountOfDuplicatedTicket}
              </label>
            </Paper>
            <Paper
              style={{
                width: "100%",
                height: "30%",
                backgroundColor: "#215e31",
              }}
            >
              <label className={classes.TicketText}>重複收費開單</label>
              <label className={classes.TicketValue}>
                {amountOfDuplicatedTicketWithCheckPoint}
              </label>
            </Paper>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "90%",
              }}
            >
              <MyBarChart labels={barLabel} dataSets={dataSetOccupancy} />
              {InfomationIconButton("Occupancy")}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "90%",
              }}
            >
              <MyBarChart labels={barLabel} dataSets={dataSetMultiBar} />
              {InfomationIconButton("SpaceStatus")}
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={4}>
                <div style={{ width: "80%", margin: "20px" }}>
                  <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                    車牌辨識失敗率
                  </span>
                  {InfomationIconButton("plateRecognitionFailRate")}
                  <DonutChart
                    data={lprFailureChartData}
                    colors={lprFailureChartDataColor}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div style={{ width: "80%", margin: "20px" }}>
                  <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                    重複開單率
                  </span>
                  {InfomationIconButton("duplicatedTicketRate")}
                  <DonutChart
                    data={duplicatedTicketChartData}
                    colors={duplicatedChartDataColor}
                    style={{ marginTop: "20px" }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div style={{ width: "80%", margin: "20px" }}>
                  <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                    重複開單收費率
                  </span>
                  {InfomationIconButton("duplicatedTicketChargedRate")}
                  <DonutChart
                    data={duplicatedTicketWithCheckPointChartData}
                    colors={duplcatedWithChargeChartDataColor}
                    style={{ marginTop: "20px" }}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            className={classes.paper}
            style={{
              height: "95%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={3}>
                <div style={{ width: "80%", margin: "20px" }}>
                  <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                    停車率
                  </span>
                  {InfomationIconButton("OccupancyRate")}
                  <DonutChart
                    data={parkingOccupancyRateChartData}
                    colors={occupiedRateChartColor}
                    style={{ marginTop: "20px" }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div style={{ width: "80%", margin: "20px" }}>
                  <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                    開單率
                  </span>
                  {InfomationIconButton("StartOrderRate")}
                  <DonutChart
                    data={parkingTicketCreationRateChartData}
                    colors={startOrderRateChartColor}
                    style={{ marginTop: "20px" }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div style={{ width: "80%", margin: "20px" }}>
                  <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                    開單收費率
                  </span>
                  {InfomationIconButton("StartOrderChargedRate")}
                  <DonutChart
                    data={parkingTicketWithCheckPointRateChartData}
                    colors={startOrderWithChargeChartColor}
                    style={{ marginTop: "20px" }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div style={{ width: "80%", margin: "20px" }}>
                  <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                    迴轉率
                  </span>
                  {InfomationIconButton("TurnoverRate")}
                  <DonutChart
                    data={parkingTurnoverRateChartData}
                    colors={turnoverRateChartColor}
                    style={{ marginTop: "20px" }}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <div>統計日期 {statisticsKey}</div>
      {RenderDeviceDialog}
      {RenderModuleFailureDeviceDialog}
      {RenderInfomationDilaog}
    </div>
  );
};

const DeviceListHeader = ["區域", "路段", "格位", "設備編號", "最後心跳時間"];
const ModuleFailureListHeader = [
  "區域",
  "路段",
  "格位",
  "設備編號",
  "最後心跳時間",
  "batteries",
  "solarPanel",
  "radars",
  "cameras",
  "touchPanel",
  "gSensor",
  "cardReader",
  "system",
];

function ISO8601ToDateTime(isoDateString) {
  const date = new Date(isoDateString);

  // 確保Date物件有效，並格式化日期和時間
  if (!isNaN(date.getTime())) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else {
    return "Invalid Date";
  }
}

export default Dashboard;

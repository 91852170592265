import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/report";


export const getMappingByParkingSpaceId = (spaceId) => {
    return apiProvider.get(`${ROOT_URL}${BASE_URL}/realtime/device/mapping/bySpaceId/${spaceId}`);
};

export const getMappingByParkingSpaceIds = (spaceIds) => {
    let ids = "";
    if (Array.isArray(spaceIds)) {
        ids = spaceIds.map((id) => `ids=${id}`).join("&");
    } else {
        ids = `ids=${spaceIds}`;
    }
    return apiProvider.get(`${ROOT_URL}${BASE_URL}/realtime/device/mapping/bySpaceIds?${ids}`);
};

export const getMappingByParkingMeterId = (meterId) => {
    return apiProvider.get(`${ROOT_URL}${BASE_URL}/realtime/device/mapping/byMeterId/${meterId}`);
};

export const getMappingByParkingMeterIds = (meterId) => {
    let ids = "";
    if (Array.isArray(meterId)) {
        ids = meterId.map((id) => `ids=${id}`).join("&");
    } else {
        ids = `ids=${meterId}`;
    }
    return apiProvider.get(`${ROOT_URL}${BASE_URL}/realtime/device/mapping/byMeterIds?${ids}`);
};

export const getMappingByParkPileId = (pileId) => {
    return apiProvider.get(`${ROOT_URL}${BASE_URL}/realtime/device/mapping/byPileId/${pileId}`);
};

export const getMappingByParkPileIds = (pileId) => {
    let ids = "";
    if (Array.isArray(pileId)) {
        ids = pileId.map((id) => `ids=${id}`).join("&");
    } else {
        ids = `ids=${pileId}`;
    }
    return apiProvider.get(`${ROOT_URL}${BASE_URL}/realtime/device/mapping/byPileIds?${ids}`);
};

export const getRealTimeDeviceReport =()=>{
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/realtime/device`);
  
}
import React, { useContext, useState, useEffect } from "react";
import {
    Box,
    FormControl,
    Grid,
    Select,
    Button,
    ButtonGroup,
    TextField,
    MenuItem,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card,
    CardContent,
    CardActions,
    Typography,
    Tooltip
} from "@material-ui/core";
import { Backdrop, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from '@material-ui/icons/Settings';
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import LaunchIcon from '@material-ui/icons/Launch';
import { green, red, blue, yellow, grey } from '@material-ui/core/colors';
import { makeStyles } from "@material-ui/core/styles";

import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Divider from '@material-ui/core/Divider';

import { firstBy } from "thenby";
import * as dayjs from 'dayjs'
import utcOffset from 'dayjs/plugin/utc'

import defaulttheme from "../../customTheme.js";
import { GlobalContext } from "../../contexts/GlobalContext";
// import {
//     getParkingPiles,
//     getParkingSpaceMapping,
//     getParkingMeterMapping,
// } from "../../utils/ParkingPileApi";

import { getParkingLots, getAllParkingLots } from "../../utils/ParkingLotApi.js";

import { getLocations } from "../../utils/LocationApi.js";
import { getParkingMeterByLocationIds, getParkingMeterById, addParkingMeterAttribute } from "../../utils/ParkingMeterApi.js";
import HourRateTable from "../component/HourRateTable.js";
import SpaceSnapshotsDialog from '../component/SpaceSnapshotsDialog.js';


dayjs.extend(utcOffset);


const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    button: {
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
    },
    paper: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    imageList: {
        flexWrap: "nowrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        width: "100%",
        height: "100%",
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 5,
        color: "#fff",
    },
    dialog: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const tableRowHeader = [
    // { "name": "區域", "key": "area" },
    { "name": "路段", "key": "section" },
    { "name": "車格名稱", "key": "parkingSpaceName" },
    // { "name": "廠牌", "key": "brand" },
    // { "name": "型號", "key": "model" },
    { "name": "設備編號", "key": "deviceId" },
    { "name": "對應類型", "key": "mappingType" },
    { "name": "佔用狀態", "key": "occupiedStatus" },
    { "name": "設備狀態", "key": "lastHeartbeat" },
    { "name": "電量狀態", "key": "batteries" },
    { "name": "是否啟用", "key": "isEnabled" },
    { "name": "格位快照", "key": "spaceSnapshot" },
    { "name": "時段表", "key": "hourRates" },
    { "name": "設備設定", "key": "deviceSetting", rowSpanEnabled: true }
];

const deviceSettingsTableItems = [
    {
        name: "車格資訊", key: "space_info", childs: [
            { name: "SLR", key: "SLR", editable: false, isId: true, type: "string", effective_time: "effective_time", note: "0: 單格設備(S), 1: 雙格設備左邊(L), 2: 雙格設備右邊(R)" },
            { name: "name", key: "name", editable: true, type: "string", effective_time: "effective_time", note: "車格名稱，可允許英文字，如 S001" },
            { name: "grace_period", key: "grace_period", editable: true, type: "int", effective_time: "effective_time", note: "離場寬限時間，單位為分鐘" },
            { name: "free_period", key: "free_period", editable: true, type: "int", effective_time: "effective_time", note: "免費停車時間，單位為分鐘，" },
            { name: "out_of_service", key: "out_of_service", editable: true, type: "int", effective_time: "effective_time", note: "是否暫停服務， 0: 暫停使用、1: 啟用" },
            { name: "effective_time", key: "effective_time", editable: true, type: "int", effective_time: "effective_time", note: "生效時間，使用 ISO 8601 格式表示" }
        ]
    },
    {
        name: "電池資訊", key: "battery", childs: [
            { name: "id", key: "id", editable: false, isId: true, note: "電池 id， 1: 電池A、2: 電池B" },
            { name: "status", key: "status", editable: false, note: "電池狀態， 0:正常、1:沒有回應" },
            { name: "percentage", key: "percentage", editable: false, note: "電池電量，以百分比顯示" },
            { name: "voltage", key: "voltage", editable: false, note: "電池電壓" },
            { name: "power", key: "power", editable: false, note: "供電狀態， 0: standby, 1: active" },
        ]
    },
    {
        name: "太陽能資訊", key: "solar_panel", childs: [
            { name: "status", key: "status", editable: false, note: "太陽能供應系統狀態， 0: 正常、1: 供電異常" },
            { name: "power", key: "power", editable: false, note: "供電狀態， 0: 正常、1: 供電異常" },
            { name: "last_power_time", key: "last_power_time", editable: false, note: "上次供電輸出時間，使用 ISO 8601 格式表示" },
        ]
    },
    {
        name: "系統資訊", key: "system", childs: [
            { name: "app_version", key: "app_version", editable: true, type: "string", effective_time: "app_version_effective_time", note: "主程式版本" },
            { name: "APP_OTA_status", key: "APP_OTA_status", editable: false, note: "主程式 OTA 狀態 (0:正常、1:失敗)" },
            { name: "app_version_effective_time", key: "app_version_effective_time", editable: true, type: "string", note: "App 版本生效時間" },
            { name: "m0_version", key: "m0_version", editable: true, type: "string", effective_time: "m0_version_effective_time", note: "M0 版本" },
            { name: "M0_OTA_status", key: "M0_OTA_status", editable: false, note: "M0 OTA 狀態 (0:正常、1:失敗)" },
            { name: "m0_version_effective_time", key: "m0_version_effective_time", editable: true, type: "string", note: "m0 版本生效時間" },
            { name: "flash", key: "flash", editable: false, note: "剩餘 Flash 大小 (KB)" },
            { name: "ram", key: "ram", editable: false, note: "Linux RAM 使用率 (百分比方式顯示)" },
            { name: "cpu", key: "cpu", editable: false, note: "Linux CPU 使用率( 百分比方式顯示)" },
            { name: "heap", key: "heap", editable: false, note: "剩餘 Heap 大小 (KB)" },
        ]
    },
    {
        name: "系統參數", key: "system_parameter", childs: [
            { name: "sleep", key: "sleep", editable: true, type: "int", effective_time: "effective_time", note: "休眠時間，單位為秒鐘" },
            { name: "car_detect_time", key: "car_detect_time", editable: true, type: "int", effective_time: "effective_time", note: "判斷有車的時間間隔，單位為秒鐘" },
            { name: "signature", key: "signature", editable: true, type: "int", effective_time: "effective_time", note: "加簽時間，單位為分鐘" },
            { name: "oper_start", key: "oper_start", editable: true, type: "string", effective_time: "effective_time", note: "營業時間起始，使用 ISO 8601 格式表示" },
            { name: "oper_end", key: "oper_end", editable: true, type: "string", effective_time: "effective_time", note: "營業時間結束，使用 ISO 8601 格式表示" },
            { name: "oper_start_heartbeat", key: "oper_start_heartbeat", editable: true, type: "int", effective_time: "effective_time", note: "營業 heartbeat 間隔，單位為分鐘" },
            { name: "oper_end_heartbeat", key: "oper_end_heartbeat", editable: true, type: "int", effective_time: "effective_time", note: "非營業 heartbeat 間隔，單位為分鐘" },
            { name: "pre_cap_delay_gap_left", key: "pre_cap_delay_gap_left", editable: true, type: "int", effective_time: "effective_time", note: "左進場預拍照片間隔，單位為秒鐘 (起拍延後時間)" },
            { name: "pre_cap_delay_count_left", key: "pre_cap_delay_count_left", editable: true, type: "int", effective_time: "effective_time", note: "左進場預拍照片張數，單位為張數 (default=3)" },
            { name: "pre_cap_delay_gap_right", key: "pre_cap_delay_gap_right", editable: true, type: "int", effective_time: "effective_time", note: "右進場預拍照片間隔，單位為秒鐘 (起拍延後時間)" },
            { name: "pre_cap_delay_count_right", key: "pre_cap_delay_count_right", editable: true, type: "int", effective_time: "effective_time", note: "右進場預拍照片張數，單位為張數 (default=3)" },
            { name: "tech_interval_time", key: "tech_interval_time", editable: true, type: "int", effective_time: "effective_time", note: "科技執法間隔時間，單位為秒" },
            { name: "tech_evidence_collection_count", key: "tech_evidence_collection_count", editable: true, type: "int", effective_time: "effective_time", note: "科技執法採證次數" },
            { name: "qrcode_display_timeout", key: "qrcode_display_timeout", editable: true, type: "int", effective_time: "effective_time", note: "QRCode付款畫面顯示時間，單位為秒" },
            { name: "card_display_timeout", key: "card_display_timeout", editable: true, type: "int", effective_time: "effective_time", note: "卡片付款畫面顯示時間，單位為秒" },
            { name: "mode", key: "mode", editable: true, type: "int", effective_time: "effective_time", note: "0: 正常模式, 1: 未啟用模式" },
            { name: "effective_time", key: "effective_time", editable: true, type: "string", note: "生效時間，使用 ISO 8601 格式表示" },
        ]
    },
    {
        name: "雷達參數", key: "radar", childs: [
            { name: "id", key: "id", editable: false, isId: true, type: "int", effective_time: "effective_time", note: "1: 雷達A、2: 雷達B" },
            { name: "status", key: "status", editable: false, note: "0: 正常、1: 沒有回應、2: 參數設定錯誤、3: OTA更新失敗" },
            { name: "version", key: "version", editable: true, type: "string", effective_time: "effective_time", note: "雷達版本" },
            { name: "OTA_status", key: "OTA_status", editable: false, note: "0: 正常、1:更新失敗" },
            { name: "x", key: "parameter:x", editable: true, type: "int", effective_time: "effective_time", note: "車格原點X" },
            { name: "y", key: "parameter:y", editable: true, type: "int", effective_time: "effective_time", note: "車格原點Y" },
            { name: "l", key: "parameter:l", editable: true, type: "int", effective_time: "effective_time", note: "車格長度" },
            { name: "w", key: "parameter:w", editable: true, type: "int", effective_time: "effective_time", note: "車格寬度" },
            { name: "h", key: "parameter:h", editable: true, type: "int", effective_time: "effective_time", note: "雷達高度" },
            { name: "zminus", key: "parameter:zminus", editable: true, type: "int", effective_time: "effective_time", note: "車格訊號高度下限" },
            { name: "zplus", key: "parameter:zplus", editable: true, type: "int", effective_time: "effective_time", note: "車格訊號高度上限" },
            { name: "deminus", key: "parameter:deminus", editable: true, type: "int", effective_time: "effective_time", note: "訊號水平範圍(-)" },
            { name: "deplus", key: "parameter:deplus", editable: true, type: "int", effective_time: "effective_time", note: "訊號水平範圍(+)" },
            { name: "dhminus", key: "parameter:dhminus", editable: true, type: "int", effective_time: "effective_time", note: "訊號垂直範圍(-)" },
            { name: "dhplus", key: "parameter:dhplus", editable: true, type: "int", effective_time: "effective_time", note: "訊號垂直範圍(+)" },
            { name: "de", key: "parameter:de", editable: true, type: "int", effective_time: "effective_time", note: "雷達與咪表水平角" },
            { name: "dh", key: "parameter:dh", editable: true, type: "int", effective_time: "effective_time", note: "雷達與咪表垂直角" },
            { name: "lr", key: "parameter:lr", editable: true, type: "int", effective_time: "effective_time", note: "雷達在面罩的左右邊" },
            { name: "tbd", key: "parameter:tbd", editable: true, type: "int", effective_time: "effective_time", note: "背景值距離邊界值" },
            { name: "ta", key: "parameter:ta", editable: true, type: "int", effective_time: "effective_time", note: "邊界值內的微調" },
            { name: "tb", key: "parameter:tb", editable: true, type: "int", effective_time: "effective_time", note: "邊界值外的微調" },
            { name: "pa", key: "parameter:pa", editable: true, type: "int", effective_time: "effective_time", note: "總取樣點數" },
            { name: "cc", key: "parameter:cc", editable: true, type: "int", effective_time: "effective_time", note: "空車轉有車比對次數" },
            { name: "ce", key: "parameter:ce", editable: true, type: "int", effective_time: "effective_time", note: "有車轉空車比對次數" },
            { name: "b", key: "parameter:b", editable: true, type: "int", effective_time: "effective_time", note: "遮蔽值設定" },
            { name: "effective_time", key: "effective_time", editable: true, type: "string", note: "生效時間" },
        ]
    },
    {
        name: "相機資訊", key: "camera", childs: [
            { name: "id", key: "id", editable: false, isId: true, note: "相機id， 1: 相機A、2: 相機B" },
            { name: "status", key: "status", editable: false, note: "相機狀態， 0: 正常、1: 沒有回應" },
            { name: "version", key: "version", editable: false, note: "相機版本" }
        ]
    },
    {
        name: "螢幕資訊", key: "screen", childs: [
            { name: "status", key: "status", editable: false, note: "螢幕狀態， 0: 正常、1: 沒有回應" },
            { name: "brightness", key: "brightness", editable: false, note: "螢幕亮度，以百分比表示" }
        ]
    },
    {
        name: "觸碰面板", key: "touch_panel", childs: [
            { name: "status", key: "status", editable: false, note: "觸控面板狀態， 0: 正常、1: 沒有回應、2: OTA更新失敗" },
            { name: "version", key: "version", editable: true, type: "string", effective_time: "effective_time", note: "觸控面板版本" },
            { name: "effective_time", key: "effective_time", editable: true, type: "string", note: "0: 正常、1:更新失敗" },
        ]
    },
    {
        name: "G 力感應器", key: "g_sensor", childs: [
            { name: "status", key: "status", editable: false, note: "G Sensor狀態， 0: 正常、1: 沒有回應、3: 異常碰撞" },
        ]
    },
    {
        name: "切換開關", key: "switch", childs: [
            { name: "value", key: "value", editable: false, note: "主板指撥開關設定" },
        ]
    },
    {
        name: "數據機資訊", key: "modem", childs: [
            { name: "status", key: "status", editable: false, note: "4G模組狀態， 0: 正常、1: 沒有回應" },
            { name: "RSRP", key: "RSRP", editable: false, note: "4G模組RSRP (dBm)" },
            { name: "RSSI", key: "RSSI", editable: false, note: "4G模組RSSI (dBm)" },
            { name: "RSRQ", key: "RSRQ", editable: false, note: "4G模組RSRQ (dB)" },
            { name: "band", key: "band", editable: false, note: "4G模組頻段" },
            { name: "IP", key: "IP", editable: false, note: "4G模組 IP" },
        ]
    },
    {
        name: "卡機資訊", key: "card_reader", childs: [
            { name: "status", key: "status", editable: false, note: "卡機狀態， 0: 正常、1: 沒有回應" },
            { name: "version", key: "version", editable: true, type: "string", effective_time: "effective_time", note: "卡機版本" },
            { name: "blacklist", key: "blacklist", editable: true, type: "string", effective_time: "effective_time", note: "黑名單列表" },
            { name: "sam_slot_1", key: "sam_slot_1", editable: false, type: "string", effective_time: "effective_time", note: "SAM卡槽 1" },
            { name: "sam_slot_2", key: "sam_slot_2", editable: false, type: "string", effective_time: "effective_time", note: "SAM卡槽 2" },
            { name: "sam_slot_3", key: "sam_slot_3", editable: false, type: "string", effective_time: "effective_time", note: "SAM卡槽 3" },
            { name: "sam_slot_4", key: "sam_slot_4", editable: false, type: "string", effective_time: "effective_time", note: "SAM卡槽 4" },
            { name: "effective_time", key: "effective_time", editable: true, type: "string", note: "" },
        ]
    }
];

const EditTextField = (type, newConfig, onChange, key, keyLevel, effective_time) => {
    const onChangeHandler = (e) => {
        // debugger;
        if (typeof onChange === "function") {
            onChange(e, keyLevel, type, effective_time);

        }

        setV(e.target.value);
    };

    // debugger;

    // const value = newConfig[keyLevel] || "";
    const value = newConfig[keyLevel] !== undefined ? newConfig[keyLevel] : "";

    // console.log('EditTextField', type, keyLevel, newConfig[keyLevel], `value=[${value}]`);

    const [v, setV] = useState(value);

    return (
        <TextField
            key={key}
            // label={label}
            variant="outlined"
            style={{ borderRadius: "20px", width: "250px" }} //圓角與自動伸縮
            value={value}
            onChange={onChangeHandler}
        />
    );
};

const generateTable = (updateAt, data, columns, title, title_key, keyWithLevel, newConfig, setNewConfig) => {
    const dataArray = Array.isArray(data) ? data : [data]; // 確保 data 是陣列
    let idColumn = columns.find(column => column.isId);
    // debugger;
    const onchange = (e, keyLevel, type, effective_time) => {
        // console.log(e);
        // console.log(e.target.value);
        // console.log(keyLevel);
        // debugger;

        if (type === "int") {
            newConfig[keyLevel] = parseInt(e.target.value);
        }
        else {
            newConfig[keyLevel] = e.target.value;
        }

        if (effective_time !== undefined && effective_time) {

            // newConfig[effective_time] = new Date().toISOString();
            newConfig[effective_time] = dayjs().utcOffset(8).add(1, 'day').set('hour', 0).set('minute', 30).utc().toISOString();
        }

        if (idColumn !== undefined) {
            newConfig[`${keyWithLevel}:${idColumn.key}`] = data[idColumn.key];
        }

        setNewConfig(newConfig);
        // console.log(newConfig);
    };

    return (
        <>
            <h3 key={title_key} style={{ marginTop: "20px", marginBottom: "5px" }}>{title} - 更新時間: ({(new Date(updateAt)).toLocaleString()})</h3>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow
                            style={{ backgroundColor: defaulttheme.blue.delta, color: "white", height: "10px" }}
                        // sx={{
                        //     "&:last-child td, &:last-child th": { border: 0 },
                        //     height: "10px",
                        //     color: "white"
                        // }}
                        >
                            <TableCell style={{ color: "white" }}>名稱</TableCell>
                            <TableCell style={{ color: "white" }}>備註</TableCell>
                            <TableCell style={{ color: "white" }}>目前的值</TableCell>
                            <TableCell style={{ color: "white" }}>新的值</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataArray.flatMap((row, rowIndex) =>
                            columns.map((column, columnIndex) => (
                                <TableRow key={`${rowIndex}-${columnIndex}`}>
                                    <TableCell>{column.name}</TableCell>
                                    <TableCell>{column.note}</TableCell>
                                    <TableCell>{row[Object.keys(row).find(key => key.toLowerCase() === column.key.toLowerCase())]}</TableCell>
                                    <TableCell>{column.editable ? EditTextField(column.type, newConfig, onchange, column.key, keyWithLevel + `:${column.key}`, keyWithLevel + `:${column.effective_time}`) : ""}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

const DeviceSettingsTables = ({ currentData, newConfig, setNewConfig }) => {
    const tables = [];

    Object.keys(deviceSettingsTableItems).forEach((key) => {
        const item = deviceSettingsTableItems[key];
        if (item.childs) {
            const columns = item.childs;
            Object.keys(currentData).forEach((dataKey) => {
                if (dataKey.toLocaleLowerCase() === item.key.toLocaleLowerCase()) {
                    let keyWithLevel = dataKey;

                    if (typeof currentData[dataKey] === undefined || currentData[dataKey] === null) {
                        return;
                    }

                    let content = currentData[dataKey].content;

                    if (Array.isArray(content)) {
                        for (let i in content) {
                            // debugger;
                            let idxKey = "id";
                            if (dataKey === "space_info") {
                                idxKey = "SLR";
                            };

                            let idx = content[i][idxKey];

                            let tmpkeyWithLevel = keyWithLevel + `:${idx}`;
                            let dispData = content[i];

                            if (dataKey === "radar") {

                                for (let key in dispData.parameter) {
                                    dispData[`parameter:${key}`] = dispData.parameter[key];
                                }

                                delete dispData.parameter;
                            }

                            // console.log(dataKey, i, item.name, item.key, dispData);
                            tables.push(generateTable(currentData[dataKey].updateAt, dispData, columns, `${item.name}`, item.key, tmpkeyWithLevel, newConfig, setNewConfig));
                        }
                    }
                    else if (dataKey === "switch") {

                        tables.push(generateTable(currentData[dataKey].updateAt, { value: content }, columns, item.name, item.key, keyWithLevel, newConfig, setNewConfig));
                    }
                    else {

                        // console.log(dataKey, item.name, item.key, testData[dataKey]);
                        tables.push(generateTable(currentData[dataKey].updateAt, content, columns, item.name, item.key, keyWithLevel, newConfig, setNewConfig));
                    }
                }
            });
        }
    });

    return (
        <Grid container spacing={2}>
            {tables.map((table, index) => (
                <Grid item xs={6} key={index}>
                    {table}
                </Grid>
            ))}
        </Grid>
    );
};


const getFlattenLocationData = async (amount = -1) => {
    let res = await getLocations();
    let resp = res.data;
    // 20 is default amount
    if (resp.total > resp.amount) {
        // fet all data
        resp = await getLocations(resp.total);
    }

    return flattenLocationData(resp);
};

const flattenLocationData = (resp) => {
    const flattenedData = [];

    function processLocation(location, parentName = '', level = 0) {
        const fullName = parentName ? `${parentName}${location.name}` : location.name;
        flattenedData.push({
            id: location.locationId,
            name: fullName,
            isEnabled: location.isEnabled,
            level: level,
            parentId: location.parentLocationId,
        });

        if (location.childs && location.childs.length > 0) {
            location.childs.forEach(child => processLocation(child, `${fullName}`, level + 1));
        }
    }

    if (resp && resp.data && Array.isArray(resp.data)) {
        resp.data.forEach(location => processLocation(location));
    }

    // console.log(flattenedData)

    return flattenedData;
}

const getParkingLotData = async (locationId) => {
    let res = await getParkingLots(locationId);
    let resp = res.data;
    // 20 is default amount
    if (resp.total > resp.amount) {
        // fet all data
        res = await getParkingLots(locationId, 0, resp.total);
        resp = res.data;
    }

    return resp.data;
}

const getAllParkingLotsData = async () => {
    let res = await getAllParkingLots();
    let resp = res.data;
    // 20 is default amount
    if (resp.total > resp.amount) {
        // fet all data
        res = await getAllParkingLots(0, resp.total);
        resp = res.data;
    }

    return resp.data;
}

const OccupiedStatusIcon = ({ status }) => {
    // -1 unkonwn
    // 0 green
    // 1 red
    // 2 yellow

    if (status === 1) {
        return <Tooltip title="Occupied"><FiberManualRecordRoundedIcon style={{ color: red[700] }} /></Tooltip>;
    }
    else if (status === 2) {
        return <Tooltip title="Empty"><FiberManualRecordRoundedIcon style={{ color: green[700] }} /></Tooltip>;
    }
    else {
        return <Tooltip title="Unknow"><HelpOutlineOutlinedIcon /></Tooltip>;
    }
}

const MappingTypeIcon = ({ type, text, color }) => {

    if (type === "left") {
        return <Tooltip title={text}><SubdirectoryArrowLeftIcon style={{ color: yellow[900] }} /></Tooltip>;
    }
    else if (type === "right") {
        return <Tooltip title={text}><SubdirectoryArrowRightIcon style={{ color: blue[900] }} /></Tooltip>;
    }
    else {
        return <Tooltip title={text}><ArrowDownwardRoundedIcon style={{ color: color }} /></Tooltip>;
    }

}

const BatteryIcon = ({ battery }) => {
    let color = grey[700];
    let key = `battery_icon_${new Date().getTime()}`;
    let cw = 700;
    let power_status = "N/A";
    let percentage_status = "N/A";
    let voltage_status = "N/A";
    let status_status = "N/A";
    if (battery) {

        if (typeof battery.power !== 'undefined') {
            power_status = battery.power === 0 ? "Standby" : "Active";
        }

        if (typeof battery.voltage !== 'undefined') {
            voltage_status = battery.voltage;
        }

        if (typeof battery.percentage !== 'undefined') {
            percentage_status = battery.percentage;
        }

        if (typeof battery.status !== 'undefined') {
            status_status = battery.status === 0 ? "Normal" : "NotResponding";
        }

        if (status_status == "Normal") {
            if (power_status == "Standby") {
                // color = blue[cw];
                color = grey[cw];
            }
            else if (power_status == "Active") {
                color = green[cw];
            }
            else {
                color = grey[cw];
            }
        }
        else {
            color = red[cw];
        }

        if (battery.percentage === null) {
            return <Tooltip title={<>供電狀態: {power_status}<br />電壓: {voltage_status}<br />電量: {percentage_status}<br />狀態: {status_status}</>}><BatteryUnknownIcon key={key} style={{ color: color }} /></Tooltip>;
        }
        else if (battery.percentage === 100) {
            return <Tooltip title={<>供電狀態: {power_status}<br />電壓: {voltage_status}<br />電量: {percentage_status}<br />狀態: {status_status}</>}><BatteryFullIcon key={key} style={{ color: color }} /></Tooltip>;
        }
        else if (battery.percentage >= 90) {
            return <Tooltip title={<>供電狀態: {power_status}<br />電壓: {voltage_status}<br />電量: {percentage_status}<br />狀態: {status_status}</>}><Battery90Icon key={key} style={{ color: color }} /></Tooltip>;
        }
        else if (battery.percentage >= 80 && battery.percentage < 90) {
            return <Tooltip title={<>供電狀態: {power_status}<br />電壓: {voltage_status}<br />電量: {percentage_status}<br />狀態: {status_status}</>}><Battery80Icon key={key} style={{ color: color }} /></Tooltip>;
        }
        else if (battery.percentage >= 60 && battery.percentage < 80) {
            return <Tooltip title={<>供電狀態: {power_status}<br />電壓: {voltage_status}<br />電量: {percentage_status}<br />狀態: {status_status}</>}><Battery60Icon key={key} style={{ color: color }} /></Tooltip>;
        }
        else if (battery.percentage >= 50 && battery.percentage < 60) {
            return <Tooltip title={<>供電狀態: {power_status}<br />電壓: {voltage_status}<br />電量: {percentage_status}<br />狀態: {status_status}</>}><Battery50Icon key={key} style={{ color: color }} /></Tooltip>;
        }
        else if (battery.percentage >= 30 && battery.percentage < 50) {
            return <Tooltip title={<>供電狀態: {power_status}<br />電壓: {voltage_status}<br />電量: {percentage_status}<br />狀態: {status_status}</>}><Battery30Icon key={key} style={{ color: color }} /></Tooltip>;
        }
        else if (battery.percentage >= 0 && battery.percentage < 30) {
            return <Tooltip title={<>供電狀態: {power_status}<br />電壓: {voltage_status}<br />電量: {percentage_status}<br />狀態: {status_status}</>}><Battery20Icon key={key} style={{ color: color }} /></Tooltip>;
        }
    }
    else {
        return <Tooltip title={<>供電狀態: {power_status}<br />電壓: {voltage_status}<br />電量: {percentage_status}<br />狀態: {status_status}</>}><BatteryUnknownIcon key={key} style={{ color: color }} /></Tooltip>
    }
};

const BatteriesIcon = ({ data }) => {
    let battery_1 = data.batteries.find((b) => b.id === 1);
    let battery_2 = data.batteries.find((b) => b.id === 2);
    let solar_panel = data.solarPanel;
    // console.log(data);

    let solar_sys = 'N/A';
    let solar_power = 'N/A';
    let solar_color = grey[700];
    if (solar_panel) {
        if (solar_panel.status === 0 && solar_panel.power === 0) {
            solar_color = green[700];
            solar_sys = '供電中';
            solar_power = '充電中';
        }
        else if (solar_panel.status === 1 && solar_panel.power === 0) {
            solar_color = grey[700];
            solar_sys = 'N/A';
            solar_power = '充電中';
        }
        else if (solar_panel.status === 0 && solar_panel.power === 1) {
            solar_color = green[700];
            solar_sys = '供電中';
            solar_power = 'N/A';
        }
        else if (solar_panel.status === 1 && solar_panel.power === 1) {
            solar_color = red[700];
            solar_sys = 'N/A';
            solar_power = 'N/A';
        }
    };

    // let SolarPanelIcon = <Tooltip title={solar_tooltip}><WbSunnyIcon style={{ color: solar_color }} /></Tooltip>;

    // return (<Box><BatteryIcon battery={battery_1} /><BatteryIcon battery={battery_2} /><SolarPanelIcon /></Box>);
    return (<Box>
        <BatteryIcon battery={battery_1} />
        <BatteryIcon battery={battery_2} />
        <Tooltip title={<>系統狀態: {solar_sys}<br />供電狀態: {solar_power}</>}><WbSunnyIcon style={{ color: solar_color }} /></Tooltip>
    </Box>)
}


const Page = () => {
    const classes = useStyles();
    const { t } = useContext(GlobalContext);
    const [isLoading, setIsLoading] = useState(true);
    const [locationData, setLocationData] = useState([]);
    const [parkingLotData, setParkingLotData] = useState([]);
    const [keywourd, setKeyword] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedParkingLot, setSelectedParkingLot] = useState("");
    const [tableData, setTableData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogData, setDialogData] = useState({});
    const [newConfig, setNewConfig] = useState({});
    const [deviceIdRowCount, setDeviceIdRowCount] = useState({});
    const [currentParkingMeterId, setCurrentParkingMeterId] = useState("");

    const [hourRateOpen, setHourRateOpen] = useState(false);
    const [hourRateData, setHourRateData] = useState([]);

    const [spaceSnapshotsOpen, setSpaceSnapshotsOpen] = useState(false);
    const [spaceIdAndTypeForSnapshots, setSpaceIdAndTypeForSnapshots] = useState({});
    const [spaceLotData, setSpaceLotData] = useState([]);




    useEffect(() => {
        // get area data
        // let select change trigger and get ParkingLot data

        getFlattenLocationData().then((data) => {
            setLocationData(data);
            setIsLoading(false);
            handleLocationChange("all");
            // console.log(data);
        });

    }, []);

    const handleCloseDlg = () => {
        setOpenDialog(false);
    };

    const handleSaveDlg = () => {
        // debugger;
        setIsLoading(true);
        let data = {
            parkingMeterId: currentParkingMeterId,
            name: "newconfig",
            content: JSON.stringify(unflatternConfigData(newConfig)),
            isEnabled: true
        };

        addParkingMeterAttribute(data)
            .then((data) => {
                setOpenDialog(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

        // console.log(newConfig);
        // console.log(currentParkingMeterId, unflatternConfigData(newConfig));
    };

    const getOnlineStatus = (time) => {
        // d.lastHeartbeat != null) ? new Date(d.lastHeartbeat).toLocaleString() : "N/A",
        // <FiberManualRecordRoundedIcon style={{ color: red[700] }} />
        if (time == null) {
            return <Tooltip title="Unkonwn"><HelpOutlineOutlinedIcon /></Tooltip>;
        }
        let lastHeartbeat = new Date(time);
        let now = new Date();
        let diff = (now - lastHeartbeat) / 1000 / 60;

        // 30 分鐘內為上線
        if (diff < 30) {
            return <Tooltip title={lastHeartbeat.toLocaleString()}><FiberManualRecordRoundedIcon style={{ color: green[700] }} /></Tooltip>;
        }
        // 60 分鐘內為告警
        else if (diff < 60) {
            return <Tooltip title={lastHeartbeat.toLocaleString()}><FiberManualRecordRoundedIcon style={{ color: yellow[700] }} /></Tooltip>;
        }
        // 其他為離線
        else {
            return <Tooltip title={lastHeartbeat.toLocaleString()}><FiberManualRecordRoundedIcon style={{ color: red[700] }} /></Tooltip>;
        }
    };

    const handleSearch = () => {
        setIsLoading(true);
        let parkingLotIds = [];
        if (selectedParkingLot === "all") {
            parkingLotData.forEach((d) => {
                parkingLotIds.push(d.parkingLotId);
                // console.log("search parking lot", d.parkingLotId)
            });
        } else {
            parkingLotIds.push(selectedParkingLot);
        }

        // console.log(parkingLotIds);
        setTableData([]);
        getParkingMeterByLocationIds(parkingLotIds)
            .then((data) => {

                // console.log(data);
                if (data.status === 200 && data.data.code === 1 && data.data.data.length > 0) {
                    let resp = data.data.data;
                    let tmpTableData = [];
                    // parkingSpaceId
                    // parkingMeterId
                    // parkingLotId
                    // parkPileId

                    resp.forEach((d) => {
                        // use d.parkingLotId to get from parkingLotData
                        let parkingLot = parkingLotData.find((p) => p.parkingLotId === d.parkingLotId);
                        // console.log(d);

                        tmpTableData.push({
                            area: "",// d.locationName, 這邊沒有這個資料
                            section: parkingLot.name,
                            parkingSpaceName: d.parkingSpaceName,
                            brand: d.brand,
                            model: d.model,
                            deviceId: d.deviceId, // 這個欄位不能動，會影響到設備設定的 groupBy
                            mappingType: <MappingTypeIcon type={d.mappingType.toLocaleLowerCase()} text={d.mappingType.toLocaleLowerCase()} />,
                            occupiedStatus: <OccupiedStatusIcon status={d.occupiedStatus} />,
                            batteries: <BatteriesIcon data={d} />,
                            isEnabled: d.isEnable ? t("yes") : t("no"),
                            lastHeartbeat: getOnlineStatus(d.lastHeartbeat),
                            hourRates: (<Button
                                variant="contained"
                                startIcon={<LaunchIcon />}
                                className={classes.button}
                                style={{
                                    backgroundColor: defaulttheme.buttonColor.blue,
                                    color: "white",
                                }}
                                onClick={(e) => handleOpenHourRate(e, d, parkingLot.name)}
                            >
                                {"打開"}
                            </Button>),
                            deviceSetting: (<Button
                                variant="contained"
                                startIcon={<SettingsIcon />}
                                className={classes.button}
                                style={{
                                    backgroundColor: defaulttheme.buttonColor.blue,
                                    color: "white",
                                }}
                                onClick={(e) => handleOpenSettings(e, d.parkingMeterId)}
                            >
                                {t("settings")}
                            </Button>),
                            spaceSnapshot: (<ButtonGroup variant="outlined" aria-label="Basic button group">
                                <Button variant="contained" startIcon={<LaunchIcon />} className={classes.button} style={{ backgroundColor: defaulttheme.buttonColor.blue, color: "white", }} onClick={(e) => handleOpenSpaceSnapshots(e, d, parkingLot, 1)}>{"格位快照"}</Button>
                                <Button variant="contained" startIcon={<LaunchIcon />} className={classes.button} style={{ backgroundColor: defaulttheme.buttonColor.blue, color: "white", }} onClick={(e) => handleOpenSpaceSnapshots(e, d, parkingLot, 2)}>{"停車單照"}</Button>
                                <Button variant="contained" startIcon={<LaunchIcon />} className={classes.button} style={{ backgroundColor: defaulttheme.buttonColor.blue, color: "white", }} onClick={(e) => handleOpenSpaceSnapshots(e, d, parkingLot, 3)}>{"科技執法"}</Button>
                            </ButtonGroup>)
                        });
                    });

                    // group tableData by tableData.deviceId


                    const groupedTableData = tmpTableData.reduce((acc, item) => {
                        const deviceId = item.deviceId;
                        if (!acc[deviceId]) {
                            acc[deviceId] = [];
                        }
                        acc[deviceId].push(item);
                        return acc;
                    }, {});

                    // console.log(JSON.stringify(groupedTableData));
                    // console.log(JSON.stringify(tableData));

                    const tableDataArray = Object.values(groupedTableData).flat();

                    let tmpDeviceIdRowCount = {};

                    tableDataArray.forEach(row => {
                        tmpDeviceIdRowCount[row.deviceId] = (tmpDeviceIdRowCount[row.deviceId] || 0) + 1;
                    });

                    let sortableGroupedTableData = [];
                    for (var i in groupedTableData) {
                        sortableGroupedTableData.push(groupedTableData[i]);
                    }
                    // debugger;
                    sortableGroupedTableData.sort(
                        firstBy((v1, v2) => {
                            return getStringCharCode(v1[0].section) - getStringCharCode(v2[0].section);
                        }).thenBy((v1, v2) => {
                            return parseInt(v1[0].parkingSpaceName) - parseInt(v2[0].parkingSpaceName);
                        })
                    )

                    setDeviceIdRowCount(tmpDeviceIdRowCount);
                    setTableData(sortableGroupedTableData);

                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getStringCharCode = (str) => {
        let charCode = 0;
        for (let i = 0; i < str.length; i++) {
            charCode += str.charCodeAt(i);
        }
        return charCode;
    };

    const handleOpenSpaceSnapshots = (evt, spaceData, lotData, type) => {
        setSpaceIdAndTypeForSnapshots(() => { return { spaceData, type } });
        setSpaceLotData(lotData)
        setSpaceSnapshotsOpen(true);
    };

    const handleOpenHourRate = (evt, data, sectionName) => {
        let d = {
            sectionName: sectionName,
            spaceName: data.parkingSpaceName,
            deviceId: data.deviceId,
            brand: data.brand,
        };
        setHourRateData(d);
        setHourRateOpen(true);
    };



    const attributeFilter = (data, name, defaultValue) => {
        try {
            // 若 name 是 "switch"，則直接回傳 content
            if (name.toLowerCase() === "switch") {
                const switchAttribute = data.find(attr => attr.name.toLowerCase() === "switch");

                return switchAttribute ?
                    {
                        content: switchAttribute.content,
                        updateAt: switchAttribute.updateAt
                    } :
                    {
                        content: defaultValue,
                        updateAt: null
                    };
            }

            // 尋找與 name 相同的物件
            const attribute = data.find(attr => attr.name.toLowerCase() === name.toLowerCase());
            if (!attribute) {
                return {
                    content: defaultValue,
                    updateAt: null
                };
            }

            if (name.toLowerCase() === "newconfig" && attribute.isEnabled === false) {
                // newconfig isEnabled = false 表示該 config 目前沒有生效
                return {
                    content: defaultValue,
                    updateAt: null
                };
            }

            // 嘗試解析 JSON，若失敗則回傳 defaultValue
            return {
                content: JSON.parse(attribute.content),
                updateAt: attribute.updateAt
            };
        } catch (error) {
            return {
                content: defaultValue,
                updateAt: null
            };
        }
    };

    const flatternConfigData = (obj, parentKey = '', result = {}) => {
        if (obj === null || obj === undefined) {
            return result;
        }
        for (let [key, value] of Object.entries(obj)) {
            let newKey = parentKey ? `${parentKey}:${key}` : key;
            if (value && typeof value === 'object' && !Array.isArray(value)) {
                flatternConfigData(value, newKey, result);
            } else if (Array.isArray(value)) {
                let indexKey = "id";
                if (key === "space_info") {
                    indexKey = "SLR";
                }

                value.forEach((item, index) => {
                    if (item === null || item === undefined) {
                        return;
                    }
                    let idx = item[indexKey];
                    flatternConfigData(item, `${newKey}:${idx}`, result);
                });
            } else {
                result[newKey] = value;
            }
        }
        return result;
    };

    const unflatternConfigData = (flattenedObj) => {
        // debugger;
        const result = {};
        for (const [compoundKey, value] of Object.entries(flattenedObj)) {
            const keys = compoundKey.split(':');
            keys.reduce((acc, key, index) => {
                if (index === keys.length - 1) {
                    acc[key] = value;
                    return value;
                }
                if (!acc[key]) {
                    // 檢查下一個鍵是否表示一個數組
                    const nextKeyIsArrayIndex = !isNaN(parseInt(keys[index + 1], 10));
                    acc[key] = nextKeyIsArrayIndex ? [] : {};
                }
                return acc[key];
            }, result);
        }

        if (result.radar && result.radar['0'] && !result.radar['0'].id) {
            result.radar['0'].id = 1;
        }

        if (result.radar && result.radar['1'] && !result.radar['1'].id) {
            result.radar['1'].id = 2;
        }

        // 消掉 empty 的資料
        // 先復原，不然顯示會有問題，先由 dcu 過濾
        if (result.radar) {
            result.radar = result.radar.filter(x => x);
        }

        if (result.space_info && result.space_info['0'] && !result.space_info['0'].SLR) {
            result.space_info['0'].SLR = 0;
        }

        if (result.space_info && result.space_info['1'] && !result.space_info['1'].SLR) {
            result.space_info['1'].SLR = 1;
        }

        if (result.space_info && result.space_info['2'] && !result.space_info['2'].SLR) {
            result.space_info['2'].SLR = 2;
        }

        if (result.space_info) {
            result.space_info = result.space_info.filter(x => x);
        }

        return result;
    };

    const handleOpenSettings = (e, parkingMeterId) => {

        setIsLoading(true)

        getParkingMeterById(parkingMeterId)
            .then((data) => {
                let sourceData = data.data.data;

                let dialogData = {
                    "device_id": sourceData.manufacturerDeviceId,
                    "model_name": sourceData.brand,
                    "battery": attributeFilter(sourceData.attributes, "battery", []),
                    "solar_panel": attributeFilter(sourceData.attributes, "SolarPanel", {}),
                    "radar": attributeFilter(sourceData.attributes, "Radar", [{ id: 1 }, { id: 2 }]),
                    "camera": attributeFilter(sourceData.attributes, "Camera", []),
                    "screen": attributeFilter(sourceData.attributes, "Screen", {}),
                    "modem": attributeFilter(sourceData.attributes, "Modem", {}),
                    "switch": attributeFilter(sourceData.attributes, "Switch", ""),
                    "g_sensor": attributeFilter(sourceData.attributes, "GSensor", {}),
                    "card_reader": attributeFilter(sourceData.attributes, "CardReader", {}),
                    "touch_panel": attributeFilter(sourceData.attributes, "TouchPanel", {}),
                    "system": attributeFilter(sourceData.attributes, "System", {}),
                    "space_info": attributeFilter(sourceData.attributes, "SpaceInfo", [], [{ SLR: 0 }, { SLR: 1 }, { SLR: 2 }]),
                    "system_parameter": attributeFilter(sourceData.attributes, "SystemParameter", {})
                };

                let testData = JSON.parse("{\"device_id\":\"00000501\",\"system_parameter\":{\"sleep\":5,\"car_detect_time\":5,\"signature\":5,\"oper_start\":\"2024-03-04T00:00:00Z\",\"oper_end\":\"2024-03-04T10:00:00Z\",\"oper_start_heartbeat\":5,\"oper_end_heartbeat\":10,\"pre_cap_delay_gap_left\":5,\"pre_cap_delay_count_left\":5,\"pre_cap_delay_gap_right\":5,\"pre_cap_delay_count_right\":5,\"effective_time\":\"2024-03-04T08:00:00Z\"}}");
                let newConfig = attributeFilter(sourceData.attributes, "newconfig", {});
                let flatData = flatternConfigData(newConfig.content);
                // console.log(flatData);
                setCurrentParkingMeterId(parkingMeterId);
                setNewConfig(flatData);

                setDialogData(dialogData);
                // console.log(dialogData);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
                setOpenDialog(true);
            });
    };

    const handleParkingLotChange = (e) => {
        setSelectedParkingLot(e);
        // console.log(e)
    };

    const handleLocationChange = (e) => {
        // console.log(e);
        if (e === selectedLocation) {
            return;
        }


        setSelectedParkingLot("");

        // get ParkingLot data
        // getParkingLotData(e.target.value);
        if (e === "all") {

            getAllParkingLotsData()
                .then((data) => {
                    if (data && data.length > 0) {
                        setParkingLotData(data);
                        setSelectedParkingLot("all")
                        // setSelectedParkingLot(data[0].parkingLotId);
                        // console.log(data[0].parkingLotId)
                    }
                    else {
                        setParkingLotData([]);
                        setSelectedParkingLot("");
                    }

                    // console.log('getAllParkingLotsData', data);
                });

        }
        else {
            // get ParkingLot data

            let result = [];

            // 定義一個函式來遞迴搜尋並收集 locationId 及其子系的所有 locationId
            function collectLocationIds(targetId) {
                // 遍歷 locationData 來尋找符合條件的項目
                locationData.forEach(location => {
                    // 如果找到比對的 locationId 或其父項目的 id，則加入到結果陣列中
                    if (location.id === targetId) {
                        result.push(location.id);
                        // 然後對每一個找到的項目，遞迴尋找其子系
                        locationData.forEach(child => {
                            if (child.parentId === location.id) {
                                collectLocationIds(child.id);
                            }
                        });
                    }
                });
            }

            // 呼叫函式開始遞迴搜尋
            collectLocationIds(e);

            // 將結果陣列轉換為逗號分隔的字串並返回
            let ids = result.join(',');

            getParkingLotData(ids)
                .then((data) => {
                    if (data && data.length > 0) {
                        setParkingLotData(data);
                        // setSelectedParkingLot(data[0].parkingLotId);
                        setSelectedParkingLot("all");
                    }
                    else {
                        setParkingLotData([]);
                        setSelectedParkingLot("");
                    }
                    // console.log(data);
                });
        }

        setSelectedLocation(e);
    };

    return (
        <>
            <Backdrop open={isLoading} style={{ zIndex: 9999, color: "#fff" }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box>
                <h3>{t("device_list")}</h3>
            </Box>
            <FormControl className={classes.formControl}>
                <Grid container spacing={3}>
                    <Grid item>
                        <label htmlFor="grouped-select">{t("space_location")}</label>
                        <Select
                            defaultValue=""
                            d="grouped-select"
                            onChange={(e) => handleLocationChange(e.target.value)}
                            style={{ width: "200px", marginLeft: "10px" }}
                            value={selectedLocation}
                        >
                            <MenuItem key="all" value="all">
                                {t("all")}
                            </MenuItem>
                            {locationData
                                ? locationData.map((loc, index) => (
                                    <MenuItem key={loc.id} value={loc.id} disabled={!loc.isEnabled}>
                                        {loc.name}
                                    </MenuItem>
                                ))
                                : null}
                        </Select>
                    </Grid>
                    <Grid item>
                        <label htmlFor="grouped-select">{t("space_section")}</label>
                        <Select
                            defaultValue=""
                            d="grouped-select"
                            onChange={(e) => handleParkingLotChange(e.target.value)}
                            style={{ width: "200px", marginLeft: "10px" }}
                            value={selectedParkingLot}
                        >
                            <MenuItem key="all" value="all">
                                {t("all")}
                            </MenuItem>
                            {parkingLotData
                                ? parkingLotData.map((d, index) => (
                                    <MenuItem key={d.parkingLotId} value={d.parkingLotId}>
                                        {d.name}
                                    </MenuItem>
                                ))
                                : null}
                        </Select>
                    </Grid>
                    {/* <Grid item>
                        <TextField
                            label="車柱名稱"
                            variant="outlined"
                            style={{ borderRadius: "20px", width: "250px" }} //圓角與自動伸縮
                        // value={searchQuery}
                        // onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Grid> */}
                    <Grid item>

                        <Button
                            variant="contained"
                            startIcon={<SearchIcon />}
                            className={classes.button}
                            style={{
                                backgroundColor: defaulttheme.buttonColor.blue,
                                color: "white",
                            }}
                            onClick={handleSearch}
                        >
                            {t("search")}
                        </Button>
                    </Grid>
                </Grid>
            </FormControl>

            {tableData.length > 0 && (<>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow
                                style={{ backgroundColor: defaulttheme.blue.delta }}
                                sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                    height: "10px",
                                }}>
                                {tableRowHeader.map((header) => (
                                    <TableCell
                                        key={header.key}
                                        align="center"
                                        style={{ color: "white", fontSize: 16, fontWeight: "400" }}
                                    >
                                        {header.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((data, key) => {
                                return data.map((dataRow, dataRowIndex) => {

                                    return (
                                        <TableRow
                                            key={key + '_' + dataRowIndex}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            style={{
                                                backgroundColor: dataRowIndex % 2 === 0 ? "white" : "#d8f1fd",
                                            }}
                                        >
                                            {tableRowHeader.map((header, headerIndex) => {

                                                if (typeof header.rowSpanEnabled === "undefined") {
                                                    header.rowSpanEnabled = false;
                                                }

                                                if (header.rowSpanEnabled) {
                                                    const validRowSpan = deviceIdRowCount[key] > 0 ? deviceIdRowCount[key] : 1;

                                                    if (dataRowIndex === 0) {
                                                        return (<TableCell
                                                            key={header.key + headerIndex + "rs"}
                                                            align="center"
                                                            id={header.key + headerIndex + "rs"}
                                                            rowSpan={validRowSpan}
                                                        >
                                                            {dataRow[header.key]}
                                                        </TableCell>);
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                }
                                                else {
                                                    return (<TableCell
                                                        key={header.key + headerIndex}
                                                        align="center"
                                                    >
                                                        {dataRow[header.key]}
                                                    </TableCell>);
                                                }
                                            })}
                                        </TableRow>
                                    );
                                });
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
            )}

            <Dialog
                maxWidth="xl"
                onClose={handleCloseDlg}
                aria-labelledby="simple-dialog-title"
                open={openDialog}
            >
                <DialogTitle id="simple-dialog-title">設備設定</DialogTitle>
                <DialogContent>
                    <h4>{dialogData['model_name']} {dialogData['device_id']}</h4>
                    <DeviceSettingsTables currentData={dialogData} newConfig={newConfig} setNewConfig={setNewConfig} />
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleSaveDlg}>
                        Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleCloseDlg}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {hourRateOpen ?
                (<HourRateTable open={hourRateOpen} setOpen={setHourRateOpen} data={hourRateData} />) : null
            }
            {spaceSnapshotsOpen ?
                (<SpaceSnapshotsDialog open={spaceSnapshotsOpen} setOpen={setSpaceSnapshotsOpen} spaceData={spaceIdAndTypeForSnapshots} lotData={spaceLotData} />) : null
            }
        </>
    );
};

export default Page;
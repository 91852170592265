import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/statistics";



export const getStatistics = (startDT) => {
 
  // 組合完整的 URL
  const url = `${ROOT_URL}${BASE_URL}?startDT=${startDT}`;

  // 呼叫 API
  return apiProvider.get(url);
};


import React, { useRef, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);
ChartJS.register(ChartDataLabels);
 

const MyBarChart = ({ dataSets, labels }) => {
  const chartData = {
    labels: labels,
    datasets: dataSets,
  };

  // 計算資料的最大值
  const maxDataValue = Math.max(
    ...dataSets.flatMap((dataset) => dataset.data) // 把所有 dataset 的 data 展開並找最大值
  );

  // 動態設定 y 軸的 max 值，加上一些緩衝 (例如 10%)
  const dynamicMax = Math.ceil(maxDataValue * 1.1);

  const options_bar = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },

      datalabels: {
        display: false,
      },

      centerText: false,
    },
    scales: {
      y: {
        beginAtZero: true, // y軸從0開始
        max: dynamicMax, // 動態設定的最大值
        ticks: { 
          stepSize: 10, // 設置格線單位為 10 
        },
      },
      x: {
        grid: {
          display: false, // 隱藏 y 軸格線
        },
      },
    },
  };

  return <Bar data={chartData} options={options_bar} />;
};

export default MyBarChart;
import React, { useRef, useEffect } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);
ChartJS.register(ChartDataLabels);


// 註冊自定義插件
const centerTextPlugin = {
  id: "centerText",
  afterDraw: (chart) => {
    const { width, height, ctx } = chart;

    // 計算百分比文本
    const data = chart.data.datasets[0].data;
    const total = data.reduce((acc, cur) => acc + cur, 0);
    const percentage = ((data[0] / total) * 100).toFixed(1) + "%"; // 顯示「使用」的百分比

    // 設置文本樣式
    const fontSize = (height / 8).toFixed(2); // 根據高度計算字體大小
    ctx.save();
    ctx.font = `bold ${fontSize}px Arial, sans-serif`; // 使用 Arial 字體
    ctx.fillStyle = "#000";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    // 繪製文本於 Doughnut 中心
    ctx.fillText(percentage, width / 2, height / 2);
    ctx.restore();
  },
};

// 將插件加入 ChartJS 註冊
ChartJS.register(centerTextPlugin);



const DonutChart = ({ data,colors, labels  }) => {
  const chartData = {
    labels: labels, 
    datasets: [
      {
        data: data, // 傳入的數據
        backgroundColor: colors ,
        hoverBackgroundColor: colors,
      },
    ],
  };

  const options_donut = {
    responsive: true, // 圖表會根據父容器自動調整大小
    plugins: {
      tooltip: {
        enabled: false, // 顯示 Tooltip
      },
      legend: {
        display: false, // 隱藏圖例
      },
      datalabels: {
        display: false,
      },
    },
  
    cutout: "70%", // 設置中心圓的大小，控制成 Donut 類型，值越大，圓心越大
  };
  

  return <Doughnut data={chartData} options={options_donut}   style={{ marginTop: "20px" }}/>;
};

export default DonutChart;
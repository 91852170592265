import React, { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../contexts/GlobalContext.js";
import { AuthContext } from "../../contexts/AuthContext.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import defaulttheme from "../../customTheme.js";
import AddIcon from "@material-ui/icons/Add";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { Button, DialogContent, DialogContentText, FormControlLabel } from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {
  uploadOTA,
  getDeviceFileTypes,
  getDeviceFiles,
  getDeviceFilesByType,
  deleteDeviceFile,
} from "../../utils/DeviceFileApi.js";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteConfirmationDialog from "../component/DeleteConfirmationDialog";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import {
  getParkingMeters,
  getParkingMeterAttributes,
  distributeDeviceFile,
  getParkingMetersOTAStatus
} from "../../utils/ParkingMeterApi.js";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  button: {
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "5px",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff",
  },
  dialog: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const UploadManage = () => {
  const classes = useStyles();
  const { t, showSnackbar } = useContext(GlobalContext);
  // const { token } = useContext(AuthContext); // 取得Token
  const [openUploadDlg, setOpenUpload] = React.useState(false);
  const [version, setVersion] = React.useState("");
  const [model, setModel] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [fileTypes, setFileTypes] = React.useState([]);
  const [currentDeviceFiles, setCurrentDeviceFiles] = React.useState([]);
  const [targetType, setTargetType] = React.useState(null);
  const [openHistoryDlg, setOpenHistory] = React.useState(false);
  const [deviceFileHistory, setDeviceFileHistory] = React.useState([]);
  const [isConfirmDialogOpen, setConfirmDlgOpen] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [deleteFunction, setDeleteFunction] = React.useState(null);
  const [isAssignDlgOpen, setAssignDlgOpen] = React.useState(false);
  const [parkingMeters, setParkingMeters] = React.useState([]);
  const [parkingMeterWithDeviceFile, setMeterWithDeviceFile] = React.useState(
    []
  );
  const [checkedMeters, setCheckedMeters] = React.useState([]);
  const [rebootNextHeartbeatMeters, setRebootNextHeartbeatMeters] = React.useState([]);
  const [assignDeviceFile, setAssignDeviceFile] = React.useState(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const fileInputRef = useRef();

  const [allCheckedStatus, setAllCheckedStatus] = React.useState(0);
  const [allRebootCheckedStatus, setAllRebootCheckedStatus] = React.useState(0);

  useEffect(() => {
    // 在組件加載的時候得到資料
    GetFileTypes();
  }, []);

  const GetCurrrentDeviceFile = async (typeNames) => {
    try {
      const fileTypeName = typeNames.map((item) => item.typeName);
      const response = await getDeviceFiles(fileTypeName);
      if (response.data.isSuccess) setCurrentDeviceFiles(response.data.data);
      // console.log(response.data);
    } catch (error) {
      setCurrentDeviceFiles([]);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const GetFileTypes = async () => {
    setOpenBackdrop(true);
    try {
      const response = await getDeviceFileTypes();
      if (response.data.isSuccess) {
        setFileTypes(response.data.data);
        GetCurrrentDeviceFile(response.data.data);
      }
      // console.log(response.data);
      setOpenBackdrop(false);
    } catch (error) {
      console.error(error);
      setFileTypes([]);
      setOpenBackdrop(false);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const handleOpenUpload = (type) => {
    setOpenUpload(true);
    setTargetType(type);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setTargetType(null);
    setModel("");
    setBrand("");
    setVersion("");
    GetFileTypes();
  };

  const handleUploadFile = async () => {
    const selectedFile = fileInputRef.current.files[0];
    const data = {
      brand: brand,
      model: model,
      fileType: targetType.typeName,
      version: version,
    };
    setOpenBackdrop(true);
    try {
      const response = await uploadOTA(selectedFile, data);
      if (response.isSuccess) {
        showSnackbar({
          message: "上傳完成",
        });
        handleCloseUpload();
      }
      setOpenBackdrop(false);
    } catch (error) {
      console.error(error);
      setOpenBackdrop(false);
      showSnackbar({
        message: "上傳失敗",
        severity: "error",
      });
    }
  };

  const ISO8601toLocalTime = (iso8601Str) => {
    const iso8601Date = new Date(iso8601Str);

    function formatLocalDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const formattedLocalDateTime = formatLocalDateTime(iso8601Date);
    return formattedLocalDateTime;
  };

  const handleOpenHistory = async (type) => {
    GetDeviceFileSByType(type.typeName);
    setTargetType(type);
    setOpenHistory(true);
  };

  const GetDeviceFileSByType = async (type) => {
    setOpenBackdrop(true);
    try {
      const response = await getDeviceFilesByType(type);
      // console.log(response.data);
      if (response.data.isSuccess) {
        const histories = response.data.data;
        histories.sort((a, b) => new Date(b.createAt) - new Date(a.createAt));
        setDeviceFileHistory(histories);
      }
      setOpenBackdrop(false);
    } catch (error) {
      setDeviceFileHistory([]);
      setOpenBackdrop(false);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
    setDeviceFileHistory([]);
  };

  const handleDeleteDeviceFile = async (file) => {
    setOpenBackdrop(true);
    try {
      const response = await deleteDeviceFile(file.deviceFileId);
      if (response.isSuccess) {
        GetDeviceFileSByType(targetType.typeName);
      }
      setOpenBackdrop(false);
    } catch (error) {
      setOpenBackdrop(false);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDlgOpen(false);
  };

  const handleOpenConfirmDialog = (item, onDeleteFunction) => {
    // console.log("open delete dlg");
    if (item === null) return;
    setConfirmDlgOpen(true);
    setItemToDelete(item);
    setDeleteFunction(() => onDeleteFunction);
  };

  const handleCloseAssignDlg = () => {
    setAssignDlgOpen(false);
    setAssignDeviceFile(null);
  };

  const handleOpenAssignDlg = async (file) => {
    setCheckedMeters([]);
    setRebootNextHeartbeatMeters([]);
    setAllRebootCheckedStatus(0);
    setAllCheckedStatus(0);
    setAssignDeviceFile(file);
    setOpenBackdrop(true);
    try {
      // 這頁沒用到 attributes, 這邊設定不取相關資料，提昇效能
      let resp = await getParkingMeters(0, 1, false);
      // 20 is default amount
      if (resp.data.total > resp.data.amount) {
        // fet all data
        resp = await getParkingMeters(0, resp.data.total, false);
      }

      const response = resp;

      if (response.data.isSuccess) {
        setParkingMeters(response.data.data);
        let parkingMeterIds = response.data.data.map((meter) => meter.parkingMeterId);

        const otaStatusData = await getParkingMetersOTAStatus(file.fileType, parkingMeterIds);

        let meterWithDeviceFile = [];
        let setRebootDevice = [];

        for (var idx in response.data.data) {
          let meterData = response.data.data[idx];
          let otaStatus = otaStatusData.data.data.find((item) => item.parkingMeterId === meterData.parkingMeterId);
          let isRebootNextHb = otaStatus ? otaStatus.rebootAtNextHeartbeat : false;
          meterWithDeviceFile.push({
            parkingMeterId: meterData.parkingMeterId,
            deviceId: meterData.manufacturerDeviceId,
            isEnable: meterData.isEnabled,
            version: otaStatus ? otaStatus.dispatchVersion : null,
            currentVersion: otaStatus ? otaStatus.currentVersion : null,
            parkPileName: otaStatus.parkPileName || "",
            isRebootAtNextHeartbeat: isRebootNextHb,
          });

          if (isRebootNextHb) {
            setRebootDevice.push(meterData.parkingMeterId);
          }
        }

        if (setRebootDevice.length === parkingMeterIds.length) {
          setAllRebootCheckedStatus(1);
        } else if (setRebootDevice.length === 0) {
          setAllRebootCheckedStatus(0);
        } else {
          setAllRebootCheckedStatus(2);
        }

        setRebootNextHeartbeatMeters(setRebootDevice);


        // const meterWithDeviceFile = await Promise.all(
        //   response.data.data.map(async (meter) => {
        //     const response2 = await getParkingMeterAttributes(
        //       meter.parkingMeterId,
        //       `NewConfig.${file.fileType}.Version`
        //     );
        //     if (response2.data.isSuccess && response2.data.data !== null) {
        //       const attr = response2.data.data;
        //       //console.log(attr[0]);
        //       return {
        //         parkingMeterId: meter.parkingMeterId,
        //         deviceId: meter.manufacturerDeviceId,
        //         isEnable: meter.isEnabled,
        //         version: attr.length > 0 ? attr[0].content : null,
        //       };
        //     } else return null;
        //   })
        // );

        setOpenBackdrop(false);

        setMeterWithDeviceFile(meterWithDeviceFile);
      }
    } catch (error) {
      setOpenBackdrop(false);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }

    setAssignDlgOpen(true);
  };

  const handleAssignDeviceFile = async () => {
    // if (checkedMeters.length <= 0 && rebootNextHeartbeatMeters.length <= 0) {
    //   handleCloseAssignDlg();
    //   return;
    // }

    setOpenBackdrop(true);
    var currentDate = new Date();
    try {
      const response = await distributeDeviceFile({
        parkingMeterIds: checkedMeters,
        rebootAtNextHeartbeat: rebootNextHeartbeatMeters,
        deviceFileId: assignDeviceFile.deviceFileId,
        validFrom: currentDate.toISOString(),
      });
      if (response.isSuccess) {
        showSnackbar({
          message: "派發成功",
        });
      }
      setOpenBackdrop(false);
    } catch (error) {
      console.error(error);
      setOpenBackdrop(false);
      showSnackbar({
        message: `派發失敗(${error.data.message})`,
        severity: "error",
      });
    }

    handleCloseAssignDlg();
  };

  const handleAllRebootCheckClicked = () => {
    if (allRebootCheckedStatus === 0) {
      // 現在是全部不選，改全選
      setAllRebootCheckedStatus(1);
      setRebootNextHeartbeatMeters(parkingMeterWithDeviceFile.map((meter) => meter.parkingMeterId));
    } else {
      // 現在是全選或部份選，改全不選
      setAllRebootCheckedStatus(0);
      setRebootNextHeartbeatMeters([]);
    }
  };

  const handleAllCheckClicked = () => {
    if (allCheckedStatus === 0) {
      // 現在是全部不選，改全選
      setAllCheckedStatus(1);
      setCheckedMeters(parkingMeterWithDeviceFile.map((meter) => meter.parkingMeterId));
    } else {
      // 現在是全選或部份選，改全不選
      setAllCheckedStatus(0);
      setCheckedMeters([]);
    }
  };

  const handleCheckRebootMeterId = (parkingMeterId) => {
    const currentIndex = rebootNextHeartbeatMeters.indexOf(parkingMeterId);
    const newCheckedMeter = [...rebootNextHeartbeatMeters];

    if (currentIndex === -1) {
      newCheckedMeter.push(parkingMeterId);
    } else {
      newCheckedMeter.splice(currentIndex, 1);
    }

    setRebootNextHeartbeatMeters(newCheckedMeter);
    if (newCheckedMeter.length === 0) {
      setAllRebootCheckedStatus(0);
    }
    else if (newCheckedMeter.length === parkingMeterWithDeviceFile.length) {
      setAllRebootCheckedStatus(1);
    }
    else {
      setAllRebootCheckedStatus(2);
    }
  };

  const handleCheckMeterId = (parkingMeterId) => {
    const currentIndex = checkedMeters.indexOf(parkingMeterId);
    const newCheckedMeter = [...checkedMeters];

    if (currentIndex === -1) {
      newCheckedMeter.push(parkingMeterId);
    } else {
      newCheckedMeter.splice(currentIndex, 1);
    }

    setCheckedMeters(newCheckedMeter);
    if (newCheckedMeter.length === 0) {
      setAllCheckedStatus(0);
    }
    else if (newCheckedMeter.length === parkingMeterWithDeviceFile.length) {
      setAllCheckedStatus(1);
    }
    else {
      setAllCheckedStatus(2);
    }
  };

  const RenderAssignmentDlg = (
    <>
      <Dialog
        className={classes.dialog}
        open={isAssignDlgOpen}
        onClose={handleCloseAssignDlg}
        aria-labelledby="addform-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="addform-dialog-title">
          {assignDeviceFile ? assignDeviceFile.fileType : null}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} style={{ margin: "10px" }}>
            <Table sx={{ minWidth: 750 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: defaulttheme.blue.delta }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: "10px",
                  }}
                >
                  <TableCell padding="checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          key="cb_all_reboot"
                          checked={allRebootCheckedStatus === 1}
                          indeterminate={allRebootCheckedStatus === 2}
                          onChange={() =>
                            handleAllRebootCheckClicked()
                          }

                        />
                      }
                      style={{ color: "white", fontSize: "16px" }}
                      label="重啟"
                      labelPlacement="top"
                    />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          key="cb_all"
                          checked={allCheckedStatus === 1}
                          indeterminate={allCheckedStatus === 2}
                          onChange={() =>
                            handleAllCheckClicked()
                          }
                        />
                      }
                      style={{ color: "white", fontSize: "16px" }}
                      label="派發"
                      labelPlacement="top"
                    /></TableCell>
                  {meterColumn.map((header) => (
                    <TableCell
                      key={header}
                      align="left"
                      style={{ color: "white", fontSize: "16px" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {parkingMeterWithDeviceFile
                  ? parkingMeterWithDeviceFile.map((meter, index) => (
                    <TableRow
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "white" : "#d8f1fd",
                      }}
                      key={meter.parkingMeterId}
                    >
                      <TableCell padding="checkbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={rebootNextHeartbeatMeters.indexOf(meter.parkingMeterId) !== -1}
                              onChange={() =>
                                handleCheckRebootMeterId(meter.parkingMeterId)
                              }
                            />
                          }
                          labelPlacement="bottom"
                        />

                      </TableCell>
                      <TableCell padding="checkbox">

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkedMeters.indexOf(meter.parkingMeterId) !== -1}
                              onChange={() =>
                                handleCheckMeterId(meter.parkingMeterId)
                              }
                            />
                          }
                          labelPlacement="bottom"
                        />
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "16px", fontWeight: "bold" }}
                        align="left"
                      >
                        {meter.deviceId}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "16px" }}
                        align="left"
                      >
                        {meter.parkPileName}
                      </TableCell>
                      <TableCell style={{ fontSize: "16px" }} align="left">
                        {meter.isEnable ? "啟用" : "停用"}
                      </TableCell>
                      <TableCell style={{ fontSize: "16px" }} align="left">
                        {meter.currentVersion}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          color: (meter.version && meter.currentVersion && meter.version !== meter.currentVersion ? "red" : "inherit"),
                        }}
                        align="left"
                      >
                        {meter.version}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          color: (meter.version && meter.currentVersion && meter.version !== meter.currentVersion ? "red" : "inherit"),
                        }}
                        align="left"
                      >
                        {(!meter.version || !meter.currentVersion) ? "N/A" : meter.version === meter.currentVersion ? "" : <HighlightOffRoundedIcon />}
                      </TableCell>
                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAssignDeviceFile}
          >
            確定
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseAssignDlg}
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const RenderDeleteConFirmDlg = (
    <>
      <DeleteConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onDelete={deleteFunction}
        item={itemToDelete}
      />
    </>
  );

  const RenderUploadHistory = (
    <>
      <Dialog
        className={classes.dialog}
        open={openHistoryDlg}
        onClose={handleCloseHistory}
        aria-labelledby="addform-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="addform-dialog-title">
          {targetType ? targetType.description : null}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} style={{ margin: "10px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: defaulttheme.blue.delta }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: "10px",
                  }}
                >
                  {rowHeader_history.map((header) => (
                    <TableCell
                      key={header}
                      align="left"
                      style={{ color: "white", fontSize: "16px" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceFileHistory
                  ? deviceFileHistory.map((file, index) => (
                    <TableRow
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "white" : "#d8f1fd",
                      }}
                      key={file.deviceFileId}
                    >
                      <TableCell
                        style={{ fontSize: "16px", fontWeight: "bold" }}
                        align="left"
                      >
                        {file.version}
                      </TableCell>
                      <TableCell style={{ fontSize: "16px" }} align="left">
                        {ISO8601toLocalTime(file.updateAt)}
                      </TableCell>
                      <TableCell style={{ fontSize: "16px" }} align="left">
                        {file.brand}
                      </TableCell>
                      <TableCell style={{ fontSize: "16px" }} align="left">
                        {file.model}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title="Assign">
                          <IconButton
                            color="primary"
                            aria-label="assignment"
                            component="span"
                            className={classes.button}
                            style={{
                              backgroundColor: defaulttheme.buttonColor.blue,
                              color: "white",
                            }}
                            onClick={() => handleOpenAssignDlg(file)}
                          >
                            <AssignmentReturnedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            color="primary"
                            aria-label="assignment"
                            component="span"
                            className={classes.button}
                            style={{
                              backgroundColor: defaulttheme.buttonColor.blue,
                              color: "white",
                            }}
                            onClick={() =>
                              handleOpenConfirmDialog(
                                file,
                                handleDeleteDeviceFile
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell> 
                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseHistory}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const RenderUploadDialog = (
    <div>
      <Dialog
        className={classes.dialog}
        open={openUploadDlg}
        onClose={handleCloseUpload}
        aria-labelledby="addform-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle id="addform-dialog-title">上傳設定</DialogTitle>
        <DialogContent>
          <form className={classes.formControl}>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="version"
                    label="版本號"
                    onChange={(e) => setVersion(e.target.value)}
                    value={version}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="brand"
                    label="廠牌"
                    onChange={(e) => setBrand(e.target.value)}
                    value={brand}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="model"
                    label="型號"
                    onChange={(e) => setModel(e.target.value)}
                    value={model}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.root} style={{ marginTop: "20px" }}>
              <input
                accept="*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                ref={fileInputRef}
              />
            </div>
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUploadFile}
          >
            確定
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseUpload}
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const RenderSelect = (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-brand-label">廠牌</InputLabel>
        <Select
          labelId="select-brand-label"
          id="simple-select-brand"
        //value={age}
        //onChange={handleChange}
        ></Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-model-label">型號</InputLabel>
        <Select
          labelId="select-model-label"
          id="simple-select-model"
        //value={age}
        //onChange={handleChange}
        ></Select>
      </FormControl>
    </>
  );

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        style={{ zIndex: 9999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <h3 style={{ margin: "20px" }}>OTA管理</h3>
      {/* {RenderSelect} */}
      <TableContainer component={Paper} style={{ margin: "10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
              {rowHeader.map((header) => (
                <TableCell
                  key={header}
                  align="left"
                  style={{ color: "white", fontSize: "16px" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fileTypes
              ? fileTypes.map((type, index) => (
                <TableRow
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd",
                  }}
                  key={type.typeName}
                >
                  <TableCell
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    align="left"
                  >
                    {type.description}
                  </TableCell>
                  <TableCell style={{ fontSize: "16px" }} align="left">
                    {currentDeviceFiles && currentDeviceFiles[type.typeName]
                      ? currentDeviceFiles[type.typeName].version
                      : null}
                  </TableCell>
                  <TableCell style={{ fontSize: "16px" }} align="left">
                    {currentDeviceFiles && currentDeviceFiles[type.typeName]
                      ? ISO8601toLocalTime(
                        currentDeviceFiles[type.typeName].updateAt
                      )
                      : null}
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title="History">
                      <IconButton
                        color="primary"
                        aria-label="history"
                        component="span"
                        className={classes.button}
                        style={{
                          backgroundColor: defaulttheme.buttonColor.blue,
                          color: "white",
                        }}
                        onClick={() => handleOpenHistory(type)}
                      >
                        <HistoryIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Add">
                      <IconButton
                        color="primary"
                        aria-label="upload"
                        component="span"
                        className={classes.button}
                        style={{
                          backgroundColor: defaulttheme.buttonColor.blue,
                          color: "white",
                        }}
                        onClick={() => handleOpenUpload(type)}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Assign">
                      <IconButton
                        color="primary"
                        aria-label="assignment"
                        component="span"
                        className={classes.button}
                        style={{
                          backgroundColor: defaulttheme.buttonColor.blue,
                          color: "white",
                        }}
                        onClick={() =>
                          handleOpenAssignDlg(
                            currentDeviceFiles[type.typeName]
                          )
                        }
                      >
                        <AssignmentReturnedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {RenderUploadDialog}
      {RenderUploadHistory}
      {RenderDeleteConFirmDlg}
      {RenderAssignmentDlg}
    </>
  );
};

const rowHeader = ["OTA類型", "最新版本", "上傳時間", "操作"];

const rowHeader_history = ["版本號", "上傳時間", "廠牌", "型號", "操作"];

const meterColumn = ["設備 ID", "車柱名稱", "啟用", "目前版本", "派發版本", "是否一致"];

export default UploadManage;
